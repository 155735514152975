/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Appendix {
  bathroomRenovation = "bathroomRenovation",
  calculations = "calculations",
  energyLabels = "energyLabels",
  energyNeutral = "energyNeutral",
  energySavingTips = "energySavingTips",
  heating = "heating",
  ledLighting = "ledLighting",
  radiators = "radiators",
  registerLabel = "registerLabel",
  ventilation = "ventilation",
  weatherStripping = "weatherStripping",
}

export enum Color {
  black = "black",
  blue = "blue",
  brown = "brown",
  darkBlue = "darkBlue",
  gray = "gray",
  green = "green",
  orange = "orange",
  purple = "purple",
  red = "red",
  silver = "silver",
  white = "white",
}

export enum CommunicationProtocol {
  aanUit = "aanUit",
  openTherm = "openTherm",
}

export enum ConnectionLoadType {
  onePhase25Ampere = "onePhase25Ampere",
  onePhase35Ampere = "onePhase35Ampere",
  onePhase40Ampere = "onePhase40Ampere",
  threePhase25Ampere = "threePhase25Ampere",
}

export enum ConnectionType {
  individual = "individual",
  serial = "serial",
}

export enum DealHistoryLinkType {
  job = "job",
  quote = "quote",
}

export enum DealOnHoldReason {
  customerNotHome = "customerNotHome",
  delayedDueToOtherWork = "delayedDueToOtherWork",
  forgotUnfulfilledAppointment = "forgotUnfulfilledAppointment",
  noInstallerAvailable = "noInstallerAvailable",
  notPlannedInTime = "notPlannedInTime",
  other = "other",
  permitIssues = "permitIssues",
  problemsWithFunding = "problemsWithFunding",
  productsNotAvailable = "productsNotAvailable",
  setInPipedrive = "setInPipedrive",
}

export enum DealStatus {
  accepted = "accepted",
  expired = "expired",
  lost = "lost",
  onHold = "onHold",
  open = "open",
}

export enum DiscountType {
  amountInclTax = "amountInclTax",
  percentageInclTax = "percentageInclTax",
}

export enum DurationUnit {
  days = "days",
  hours = "hours",
  minutes = "minutes",
  seconds = "seconds",
}

export enum DutyName {
  cashback = "cashback",
  energiepaspoort = "energiepaspoort",
  energyIndex = "energyIndex",
  energyLabel = "energyLabel",
  fullElectricAdvice = "fullElectricAdvice",
  nomAdvice = "nomAdvice",
  quoteDiscount = "quoteDiscount",
  subsidy = "subsidy",
  taxation = "taxation",
  virtualVisit = "virtualVisit",
  visit = "visit",
}

export enum DutyPackageName {
  CMISComplete = "CMISComplete",
  CMISDirect = "CMISDirect",
  CMISSimple = "CMISSimple",
  RISKSimple = "RISKSimple",
  RISKSimpleGreen = "RISKSimpleGreen",
  analyseComplete = "analyseComplete",
  analyseSimple = "analyseSimple",
  budgetThuisComplete = "budgetThuisComplete",
  budgetThuisNoIntake = "budgetThuisNoIntake",
  budgetThuisTelesales = "budgetThuisTelesales",
  compareWithIsaFree = "compareWithIsaFree",
  complete = "complete",
  dataFanaticsSimple = "dataFanaticsSimple",
  direct = "direct",
  drutenWijchen = "drutenWijchen",
  energiepaspoortLegacy = "energiepaspoortLegacy",
  energiepaspoortSupplementary = "energiepaspoortSupplementary",
  energyLabelHouseTypeApartment = "energyLabelHouseTypeApartment",
  energyLabelHouseTypeDetached = "energyLabelHouseTypeDetached",
  energyLabelHouseTypeDuplex = "energyLabelHouseTypeDuplex",
  energyLabelHouseTypeRow = "energyLabelHouseTypeRow",
  energyLabelSupplementary = "energyLabelSupplementary",
  ennatuurlijk = "ennatuurlijk",
  financieelFitPlan = "financieelFitPlan",
  financieelFitSimplePlus = "financieelFitSimplePlus",
  florius = "florius",
  floriusGreen = "floriusGreen",
  freeCampaign = "freeCampaign",
  fullPlan = "fullPlan",
  gavoora = "gavoora",
  habitata = "habitata",
  habitataLingewaard = "habitataLingewaard",
  homeInvest = "homeInvest",
  hpHypothekenSimplePlus = "hpHypothekenSimplePlus",
  hpHypothekenSimplePlusGreen = "hpHypothekenSimplePlusGreen",
  hypotheekVisieComplete = "hypotheekVisieComplete",
  impact = "impact",
  ingComplete = "ingComplete",
  installerPartnerSimple = "installerPartnerSimple",
  lotHypotheken = "lotHypotheken",
  mantgumComplete = "mantgumComplete",
  muntHypotheken = "muntHypotheken",
  nummerNulComplete = "nummerNulComplete",
  offerteAdviseurSimple = "offerteAdviseurSimple",
  offerteNL = "offerteNL",
  overstappenComplete = "overstappenComplete",
  promodomo = "promodomo",
  quotesDirect = "quotesDirect",
  quotesOnly = "quotesOnly",
  republishSimple = "republishSimple",
  simpelSubsidie = "simpelSubsidie",
  simple = "simple",
  slimsterSimple = "slimsterSimple",
  slimsterSimpleFree = "slimsterSimpleFree",
  soldsupply = "soldsupply",
  solvariSimple = "solvariSimple",
  triodos = "triodos",
  triodosLabelDifference = "triodosLabelDifference",
  triodosLabelOnly = "triodosLabelOnly",
  triodosPlusLabel = "triodosPlusLabel",
  vasteLastenBond = "vasteLastenBond",
  vasteLastenBondPV = "vasteLastenBondPV",
  veldsinkComplete = "veldsinkComplete",
  veldsinkSimple = "veldsinkSimple",
  vennComplete = "vennComplete",
  viisiSimple = "viisiSimple",
  viisiSimpleGreen = "viisiSimpleGreen",
  vvaaComplete = "vvaaComplete",
  vvaaSimple = "vvaaSimple",
  yonegoSimple = "yonegoSimple",
}

export enum EnergyLabel {
  A = "A",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
}

export enum FeatureType {
  ALREADY_INSULATED = "ALREADY_INSULATED",
  INSULATABLE = "INSULATABLE",
  NONE = "NONE",
  UNINSULATABLE = "UNINSULATABLE",
}

export enum FinishType {
  board = "board",
  strip = "strip",
}

export enum FuseBoxPhases {
  single = "single",
  three = "three",
}

export enum GlassType {
  double = "double",
  hr = "hr",
  hrPlus = "hrPlus",
  hrPlus2 = "hrPlus2",
  single = "single",
  triple = "triple",
  vacuum = "vacuum",
}

export enum HeatPumpType {
  airAir = "airAir",
  airWater = "airWater",
  brineWater = "brineWater",
  groundWaterWater = "groundWaterWater",
}

export enum HeatingSetting {
  allElectric = "allElectric",
  alternating = "alternating",
  combined = "combined",
}

export enum HeatingSystemType {
  highTempConvector = "highTempConvector",
  highTempRadiator = "highTempRadiator",
  lowTempConvector = "lowTempConvector",
  lowTempRadiator = "lowTempRadiator",
  underfloorHeating = "underfloorHeating",
}

export enum InstallerAgentRole {
  director = "director",
  installer = "installer",
  planner = "planner",
}

export enum InstallerStatus {
  active = "active",
  inactive = "inactive",
  paused = "paused",
  setup = "setup",
}

export enum InstallerStatusReason {
  collaborationStopped = "collaborationStopped",
  invalidPrices = "invalidPrices",
  noCapacity = "noCapacity",
  other = "other",
}

export enum IntakeIncompletionReasonCategory {
  insideSales = "insideSales",
  insufficientPTCResults = "insufficientPTCResults",
  noTime = "noTime",
}

export enum IntakeMethod {
  physical = "physical",
  virtual = "virtual",
}

export enum InverterMonitoring {
  both = "both",
  none = "none",
  wired = "wired",
  wireless = "wireless",
}

export enum InverterType {
  individual = "individual",
  parallel = "parallel",
  serial = "serial",
}

export enum JobFollowUpType {
  invoice = "invoice",
  invoiceBtw = "invoiceBtw",
  invoiceISDE = "invoiceISDE",
  ptcNewQuote = "ptcNewQuote",
  ptcNoChanges = "ptcNoChanges",
}

export enum JobIssueReason {
  installationIncomplete = "installationIncomplete",
  installerCausedDamage = "installerCausedDamage",
  materialsNotAvailable = "materialsNotAvailable",
  notProperlyConnected = "notProperlyConnected",
  other = "other",
  wrongProduct = "wrongProduct",
}

export enum JobMutationReason {
  executedDifferently = "executedDifferently",
  ptcResult = "ptcResult",
}

export enum JobPlanningStatus {
  noProposal = "noProposal",
  plannedIn = "plannedIn",
  proposalExpired = "proposalExpired",
  proposalRejected = "proposalRejected",
  proposalSent = "proposalSent",
  uncompletedPassedPlannedDate = "uncompletedPassedPlannedDate",
  unsent = "unsent",
}

export enum JobPlanningUpdateRequestStatus {
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Open = "Open",
}

export enum JobReplanningReason {
  customerWantsAnotherDate = "customerWantsAnotherDate",
  incorrectDateOriginallyChosen = "incorrectDateOriginallyChosen",
  installerCapacity = "installerCapacity",
  installerNoShow = "installerNoShow",
  other = "other",
}

export enum JobStatus {
  completed = "completed",
  plannedIn = "plannedIn",
  proposalSent = "proposalSent",
  rated = "rated",
  sent = "sent",
  signedOff = "signedOff",
  suspended = "suspended",
  unsent = "unsent",
}

export enum JobSuspensionReason {
  materialsNotAvailable = "materialsNotAvailable",
  noInstallerInArea = "noInstallerInArea",
  notInExpertise = "notInExpertise",
  notInWorkArea = "notInWorkArea",
  other = "other",
  quoteNotApplicable = "quoteNotApplicable",
  wantToPtcFirst = "wantToPtcFirst",
  weather = "weather",
}

export enum JobType {
  INSTALLATION = "INSTALLATION",
  PTC = "PTC",
}

export enum MailjetTemplateDescriptor {
  AfterSustainabilized = "AfterSustainabilized",
  AfterVisitAdvice = "AfterVisitAdvice",
  AfterVisitNoSolutions = "AfterVisitNoSolutions",
  AfterVisitQuotes = "AfterVisitQuotes",
  AppointmentConfirmationInstallationFloorInsulation = "AppointmentConfirmationInstallationFloorInsulation",
  AppointmentConfirmationInstallationGlazing = "AppointmentConfirmationInstallationGlazing",
  AppointmentConfirmationInstallationHeatpump = "AppointmentConfirmationInstallationHeatpump",
  AppointmentConfirmationInstallationOtherProducts = "AppointmentConfirmationInstallationOtherProducts",
  AppointmentConfirmationInstallationPv = "AppointmentConfirmationInstallationPv",
  AppointmentConfirmationInstallationWallInsulation = "AppointmentConfirmationInstallationWallInsulation",
  AppointmentConfirmationPtc = "AppointmentConfirmationPtc",
  AppointmentReminderInstallation = "AppointmentReminderInstallation",
  AppointmentReminderPtc = "AppointmentReminderPtc",
  AppointmentReminderPtcCustomerPrep = "AppointmentReminderPtcCustomerPrep",
  BespaarbuddiesCongratulations = "BespaarbuddiesCongratulations",
  BespaarbuddiesCongratulationsNoKickBack = "BespaarbuddiesCongratulationsNoKickBack",
  BespaarbuddiesIntro = "BespaarbuddiesIntro",
  BespaarbuddiesIntroNoKickback = "BespaarbuddiesIntroNoKickback",
  BespaarbuddiesReminder = "BespaarbuddiesReminder",
  BespaarbuddiesReminderNoKickback = "BespaarbuddiesReminderNoKickback",
  BespaarcheckResults = "BespaarcheckResults",
  CentralHeatingAfterCare = "CentralHeatingAfterCare",
  CheckIfFundingAvailable = "CheckIfFundingAvailable",
  CongratulationsOnFirstInstallation = "CongratulationsOnFirstInstallation",
  CongratulationsOnMoreInstallations = "CongratulationsOnMoreInstallations",
  ContactFormCustomer = "ContactFormCustomer",
  CustomerHouseVisitReminder = "CustomerHouseVisitReminder",
  CustomerMagicLink = "CustomerMagicLink",
  CustomerVirtualHouseVisitReminder = "CustomerVirtualHouseVisitReminder",
  DateSelectInstallation = "DateSelectInstallation",
  DateSelectPtc = "DateSelectPtc",
  DealLostDueToAdviser = "DealLostDueToAdviser",
  EnergyPassport = "EnergyPassport",
  FailedToSendEmailAlert = "FailedToSendEmailAlert",
  Feedback = "Feedback",
  FloorInsulationAftercare = "FloorInsulationAftercare",
  GeneralCallConfirmation = "GeneralCallConfirmation",
  GeneralCallReminder = "GeneralCallReminder",
  GeneralCheckup = "GeneralCheckup",
  GeneralCloser = "GeneralCloser",
  GlazingAftercare = "GlazingAftercare",
  HeatpumpAftercare = "HeatpumpAftercare",
  HouseVisitCancellation = "HouseVisitCancellation",
  HouseVisitConfirmation = "HouseVisitConfirmation",
  InsideSalesAppointmentConfirmation = "InsideSalesAppointmentConfirmation",
  InsideSalesAppointmentReminder = "InsideSalesAppointmentReminder",
  InstallerAgentMagicLink = "InstallerAgentMagicLink",
  InstallerInvoiceReminder = "InstallerInvoiceReminder",
  InstallerJobPlanningConfirmedWithCustomer = "InstallerJobPlanningConfirmedWithCustomer",
  InstallerPlanning20PercReminder = "InstallerPlanning20PercReminder",
  InstallerPlanning50PercReminder = "InstallerPlanning50PercReminder",
  InstallerPlanningConfirmation = "InstallerPlanningConfirmation",
  InstallerPlanningConfirmationToInstaller = "InstallerPlanningConfirmationToInstaller",
  InstallerPlanningReminder = "InstallerPlanningReminder",
  InstallerSelected = "InstallerSelected",
  InternalAnnouncement = "InternalAnnouncement",
  Invoice = "Invoice",
  InvoiceBtw = "InvoiceBtw",
  InvoiceISDE = "InvoiceISDE",
  JobQuestion = "JobQuestion",
  JobQuestionToInstaller = "JobQuestionToInstaller",
  JobSuspendedByDEBToInstaller = "JobSuspendedByDEBToInstaller",
  JobSuspendedByInstallerToInstaller = "JobSuspendedByInstallerToInstaller",
  JobSuspendedToCustomer = "JobSuspendedToCustomer",
  JobSuspendedToDEB = "JobSuspendedToDEB",
  MultiplePlanningProposals = "MultiplePlanningProposals",
  NewJobInstallerAccount = "NewJobInstallerAccount",
  NoProposalOptionSuitableToCustomer = "NoProposalOptionSuitableToCustomer",
  NoProposalOptionSuitableToInstaller = "NoProposalOptionSuitableToInstaller",
  PTCResultsReminder = "PTCResultsReminder",
  PartnerDossierTransferred = "PartnerDossierTransferred",
  PartnerInstallationDoneNotification = "PartnerInstallationDoneNotification",
  PartnerLabelRegistrationNotification = "PartnerLabelRegistrationNotification",
  PartnerPortalResetPassword = "PartnerPortalResetPassword",
  PartnerPreDossierFinished = "PartnerPreDossierFinished",
  PartnerQuoteAcceptedNotification = "PartnerQuoteAcceptedNotification",
  PartnerWelcomeActivate = "PartnerWelcomeActivate",
  PaymentConfirmation = "PaymentConfirmation",
  PlanningConfirmationReminderToInstaller = "PlanningConfirmationReminderToInstaller",
  PlanningUpdateRequest = "PlanningUpdateRequest",
  PlanningUpdateRequestToInstaller = "PlanningUpdateRequestToInstaller",
  PostPTCExpectations = "PostPTCExpectations",
  PostPtcNoChanges = "PostPtcNoChanges",
  PreVisitCallConfirmation = "PreVisitCallConfirmation",
  ProposalOptionConfirmedToCustomer = "ProposalOptionConfirmedToCustomer",
  ProposalOptionConfirmedToInstaller = "ProposalOptionConfirmedToInstaller",
  PvAftercare = "PvAftercare",
  PvCheckup = "PvCheckup",
  PvSignup = "PvSignup",
  QuoteAccepted = "QuoteAccepted",
  QuoteAcceptedSubjectToFunding = "QuoteAcceptedSubjectToFunding",
  QuoteExpirationReminder = "QuoteExpirationReminder",
  QuotePdfs = "QuotePdfs",
  QuotesReady = "QuotesReady",
  RatingsOverviewToInstaller = "RatingsOverviewToInstaller",
  RequestConfirmationGeneral = "RequestConfirmationGeneral",
  RequestConfirmationPartner = "RequestConfirmationPartner",
  RequestConfirmationPartnerAgent = "RequestConfirmationPartnerAgent",
  RequestConfirmationSelfServicePlanning = "RequestConfirmationSelfServicePlanning",
  RequestConfirmationWithPaymentUrl = "RequestConfirmationWithPaymentUrl",
  RequestEnergyLabelOnly = "RequestEnergyLabelOnly",
  RequestLabelConfirmationPartnerAgent = "RequestLabelConfirmationPartnerAgent",
  RequestQuotesConfirmation = "RequestQuotesConfirmation",
  RequestedSupplementalPackage = "RequestedSupplementalPackage",
  RoofInsulationAftercare = "RoofInsulationAftercare",
  SamenResetPassword = "SamenResetPassword",
  ScoreNotificationCSAT = "ScoreNotificationCSAT",
  SelfServiceIntakeReminder = "SelfServiceIntakeReminder",
  SendWoningKeurenLead = "SendWoningKeurenLead",
  SimpelSubsidieInfo = "SimpelSubsidieInfo",
  SimpelSubsidieInstructions = "SimpelSubsidieInstructions",
  SinglePlanningProposal = "SinglePlanningProposal",
  SustainablyFinanced = "SustainablyFinanced",
  Unknown = "Unknown",
  UpgradeElectricityConnection = "UpgradeElectricityConnection",
  UpgradeElectricityConnectionCheckUp = "UpgradeElectricityConnectionCheckUp",
  UpgradeElectricityConnectionModification = "UpgradeElectricityConnectionModification",
  VirtualHouseVisitConfirmation = "VirtualHouseVisitConfirmation",
  VisitPlanningCSAT = "VisitPlanningCSAT",
  WallInsulationAftercare = "WallInsulationAftercare",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  OPEN = "OPEN",
  PAID = "PAID",
  PAYMENT_CANCELLED = "PAYMENT_CANCELLED",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PENDING = "PENDING",
}

export enum PanelType {
  all = "all",
  budget = "budget",
  premium = "premium",
}

export enum PartnerAgentRole {
  Admin = "Admin",
  CompanyManager = "CompanyManager",
  Employee = "Employee",
  MultiOfficeManager = "MultiOfficeManager",
  OfficeManager = "OfficeManager",
  OrganizationManager = "OrganizationManager",
}

export enum PartnerCollectiveKey {
  dak = "dak",
  homeInvest = "homeInvest",
}

export enum PartnerMortgageLender {
  abn = "abn",
  aegon = "aegon",
  allianz = "allianz",
  argenta = "argenta",
  asn = "asn",
  asr = "asr",
  attens = "attens",
  bijBouwe = "bijBouwe",
  blg = "blg",
  centraalBeheer = "centraalBeheer",
  cmis = "cmis",
  deNederlandse = "deNederlandse",
  dynamic = "dynamic",
  florius = "florius",
  groeneHart = "groeneHart",
  handelsbanken = "handelsbanken",
  hollandWoont = "hollandWoont",
  hypotrust = "hypotrust",
  impact = "impact",
  ing = "ing",
  iqwoon = "iqwoon",
  lloyds = "lloyds",
  lot = "lot",
  merius = "merius",
  moneyou = "moneyou",
  munt = "munt",
  nationaleNederlanden = "nationaleNederlanden",
  nibc = "nibc",
  obvion = "obvion",
  onderlinge = "onderlinge",
  other = "other",
  philips = "philips",
  rabobank = "rabobank",
  regiobank = "regiobank",
  robuust = "robuust",
  sns = "sns",
  syntrus = "syntrus",
  tellius = "tellius",
  triodos = "triodos",
  tulp = "tulp",
  vanLanschot = "vanLanschot",
  venn = "venn",
  verder = "verder",
  vista = "vista",
  volksbank = "volksbank",
  woonNu = "woonNu",
  woonfonds = "woonfonds",
}

export enum Placement {
  both = "both",
  exterior = "exterior",
  interior = "interior",
}

export enum PlannerUserType {
  customer = "customer",
  installerAgent = "installerAgent",
  operator = "operator",
}

export enum PriceAvailability {
  discontinued = "discontinued",
  inStock = "inStock",
  outOfStock = "outOfStock",
  preOrder = "preOrder",
}

export enum PriceUnit {
  hour = "hour",
  m = "m",
  m2 = "m2",
  m3 = "m3",
  unit = "unit",
}



export enum ProductDescriptorType {
  CircuitBreakerDescriptor = "CircuitBreakerDescriptor",
  FuseBoxDescriptor = "FuseBoxDescriptor",
  GenericProductDescriptor = "GenericProductDescriptor",
  InstallationMaterialDescriptor = "InstallationMaterialDescriptor",
  InverterDescriptor = "InverterDescriptor",
  LaborCostDescriptor = "LaborCostDescriptor",
  PanelDescriptor = "PanelDescriptor",
}

export enum ProductTier {
  budget = "budget",
  premium = "premium",
}

export enum PvCellType {
  hjt = "hjt",
  mono = "mono",
  nType = "nType",
  perc = "perc",
  poly = "poly",
  standard = "standard",
}

export enum PvPanelType {
  glassFoil = "glassFoil",
  glassGlass = "glassGlass",
  integrated = "integrated",
  roofTile = "roofTile",
}

export enum QuoteCreationMethod {
  Installatron = "Installatron",
  QuoteBuilder = "QuoteBuilder",
}

export enum QuoteExperiment {
  d2cOperational = "d2cOperational",
  d2cSmokeScreen = "d2cSmokeScreen",
}

export enum QuotePackageGoal {
  Comfort = "Comfort",
  Emission = "Emission",
  EnergyLabel = "EnergyLabel",
  PaybackTime = "PaybackTime",
}

export enum RefrigerantType {
  R290 = "R290",
  R32 = "R32",
  R410 = "R410",
}

export enum RoofType {
  FLAT = "FLAT",
  SLANTED = "SLANTED",
}

export enum SignableUserType {
  customer = "customer",
  installerAgent = "installerAgent",
  operator = "operator",
}

export enum SignoffType {
  customer = "customer",
  energiebespaarders = "energiebespaarders",
  installer = "installer",
}

export enum Slurper {
  airConditioning = "airConditioning",
  electricCar = "electricCar",
  heatPump = "heatPump",
  none = "none",
  other = "other",
  swimmingPool = "swimmingPool",
  underfloorHeating = "underfloorHeating",
  waterBed = "waterBed",
}

// Modified by utilities/deduplicateConstantTypes.js
import { Solution, ProductCategory } from '@energiebespaarders/constants';
export { Solution, ProductCategory };

export enum SpaceType {
  living = "living",
  sleeping = "sleeping",
}

export enum SplitUnitType {
  monoblock = "monoblock",
  split = "split",
}

export enum TimeSlotPriority {
  chronological = "chronological",
  travelDistance = "travelDistance",
}

export enum UserType {
  customer = "customer",
  installerAgent = "installerAgent",
  lead = "lead",
  operator = "operator",
  partnerAgent = "partnerAgent",
}

export enum WaitingFor {
  customer = "customer",
  deb = "deb",
  installer = "installer",
  migration = "migration",
  setInPipedrive = "setInPipedrive",
}

export enum WallSurfaceType {
  ALREADY_INSULATED = "ALREADY_INSULATED",
  INSULATABLE = "INSULATABLE",
  NONE = "NONE",
  UNINSULATABLE = "UNINSULATABLE",
}

export enum WindowType {
  DOUBLE = "DOUBLE",
  HR = "HR",
  HRP = "HRP",
  HRPP = "HRPP",
  HRPPP = "HRPPP",
  SINGLE = "SINGLE",
  UNKNOWN = "UNKNOWN",
}

/**
 * An enum describing what kind of type a given `__Type` is.
 */
export enum __TypeKind {
  ENUM = "ENUM",
  INPUT_OBJECT = "INPUT_OBJECT",
  INTERFACE = "INTERFACE",
  LIST = "LIST",
  NON_NULL = "NON_NULL",
  OBJECT = "OBJECT",
  SCALAR = "SCALAR",
  UNION = "UNION",
}

export interface AcceptedSubjectToFundingInput {
  readonly date: any;
  readonly dateFundingExpected: any;
}

export interface AddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
  readonly coordinates?: CoordinatesInput | null;
  readonly city?: string | null;
  readonly street?: string | null;
  readonly municipality?: string | null;
}

export interface AdviceInput {
  readonly id?: string | null;
  readonly houseId?: string | null;
  readonly operatorId?: string | null;
  readonly appendices?: ReadonlyArray<Appendix> | null;
  readonly floorInsulation?: SolutionAdviceInput | null;
  readonly heating?: SolutionAdviceInput | null;
  readonly insulationGlazing?: SolutionAdviceInput | null;
  readonly paid?: any | null;
  readonly opened?: any | null;
  readonly pvSystem?: SolutionAdviceInput | null;
  readonly requested?: any | null;
  readonly roofInsulation?: SolutionAdviceInput | null;
  readonly sent?: any | null;
  readonly solutionDomainOrder?: ReadonlyArray<string> | null;
  readonly summary?: string | null;
  readonly wallInsulation?: SolutionAdviceInput | null;
  readonly miscellaneous?: SolutionAdviceInput | null;
}

export interface AdviserAppointmentSlotInput {
  readonly start: any;
  readonly end: any;
  readonly duration: DurationInput;
  readonly adviserId: string;
  readonly priority?: number | null;
}

export interface AppliancesInput {
  readonly ledLighting?: boolean | null;
  readonly dishwasher?: boolean | null;
  readonly shower?: boolean | null;
  readonly savingShower?: boolean | null;
  readonly bathtub?: boolean | null;
  readonly kitchenBoiler?: boolean | null;
  readonly boilerType?: number | null;
}

export interface AppointmentInput {
  readonly date: any;
  readonly endDate?: any | null;
  readonly type?: string | null;
  readonly notes?: string | null;
}

export interface BasicAddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
}

export interface BrandInput {
  readonly name: string;
}

export interface BusinessInput {
  readonly name?: string | null;
  readonly address?: AddressInput | null;
  readonly vat?: string | null;
  readonly coc?: number | null;
  readonly iban?: string | null;
}

export interface CancellationInformationInput {
  readonly reason: string;
  readonly note?: string | null;
  readonly party: string;
}

export interface CancellationInput {
  readonly reason: string;
  readonly comment?: string | null;
  readonly operatorId: string;
}

export interface CavityAdviceTextSavingsInput {
  readonly meters: string;
  readonly moneySavings: string;
  readonly gasSavings: string;
  readonly investment: string;
  readonly paybackTime: string;
}

export interface ChargingBoxInput {
  readonly fixedCable?: boolean | null;
  readonly distanceFuseBox?: number | null;
  readonly diggingRequired?: boolean | null;
  readonly fuseBoxNextToOutsideWall?: boolean | null;
  readonly attachToWall?: string | null;
  readonly cableRouteDescription?: string | null;
  readonly customerWillingToRepair?: boolean | null;
  readonly metersToBeDug?: number | null;
  readonly onOwnProperty?: boolean | null;
  readonly loadBalancing?: boolean | null;
  readonly businessExpensing?: boolean | null;
  readonly drillingAmount?: number | null;
}

export interface CircuitBreakerDescriptorInput {
  readonly fuseBoxPhases: FuseBoxPhases;
}

export interface ConsentInput {
  readonly date: any;
  readonly medium: string;
  readonly title: string;
  readonly version: number;
  readonly withdrawn?: any | null;
}

export interface ConsumptionInput {
  readonly electricity?: number | null;
  readonly gas?: number | null;
}

export interface ContactInput {
  readonly email?: string | null;
  readonly phone?: string | null;
  readonly name?: string | null;
  readonly role?: string | null;
}

export interface ConversionPredictionInput {
  readonly probability: number;
  readonly solutions: ReadonlyArray<Solution>;
  readonly reasoning: string;
}

export interface CoordinatesInput {
  readonly longitude?: number | null;
  readonly latitude?: number | null;
}

export interface CouponInput {
  readonly code?: string | null;
  readonly prefix?: string | null;
  readonly suffix?: string | null;
  readonly percentage?: number | null;
  readonly amount?: number | null;
  readonly expirationDate?: any | null;
  readonly created?: any | null;
  readonly numberOfUses: number;
  readonly partner?: string | null;
  readonly campaign?: string | null;
  readonly applicableFor?: ReadonlyArray<string> | null;
}

export interface CrawlspaceInput {
  readonly compartments?: number | null;
  readonly height?: number | null;
  readonly insulatableArea?: number | null;
  readonly ventilation?: boolean | null;
}

export interface CrawlspaceSituationInput {
  readonly compartmentsAccessible?: string | null;
  readonly hatchAccessible?: boolean | null;
  readonly hatchLocation?: ReadonlyArray<string> | null;
  readonly obstruction?: boolean | null;
  readonly obstructionClearableByCustomer?: boolean | null;
  readonly passagesRequired?: number | null;
  readonly pipework?: boolean | null;
  readonly rubbish?: boolean | null;
  readonly separator?: string | null;
  readonly wetness?: string | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly solutionAdvised?: string | null;
  readonly insulationPerimeter?: number | null;
  readonly advisedInsulationThickness?: number | null;
  readonly hatchLength?: number | null;
  readonly hatchWidth?: number | null;
}

export interface CreatePartnerAgentInput {
  readonly gender?: string | null;
  readonly email: string;
  readonly password?: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly shared?: boolean | null;
  readonly role?: PartnerAgentRole | null;
}

export interface CreateQuoteInput {
  readonly items: ReadonlyArray<InstallationItemInput>;
  readonly solution: Solution;
  readonly energyDelta: EnergyDeltaInput;
  readonly discount?: DiscountInput | null;
  readonly pvSystemInfo?: QuotePvSystemInfoInput | null;
  readonly final?: any | null;
  readonly files?: ReadonlyArray<any> | null;
  readonly text?: string | null;
  readonly isAdvised?: boolean | null;
  readonly experiments?: ReadonlyArray<QuoteExperiment> | null;
}

export interface CurrentInsulationInput {
  readonly locations?: ReadonlyArray<string> | null;
  readonly material?: ReadonlyArray<string> | null;
  readonly thickness?: number | null;
}

export interface CurrentSystemInput {
  readonly energyYield?: number | null;
  readonly energyYieldPercentage?: number | null;
  readonly panels?: number | null;
  readonly panelBrand?: string | null;
  readonly panelPower?: number | null;
  readonly panelType?: string | null;
  readonly inverterBrand?: string | null;
  readonly extensible?: string | null;
  readonly inverterPower?: number | null;
  readonly needsDisassembly?: boolean | null;
}

export interface CustomerEmailInput {
  readonly id: string;
  readonly gender?: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone?: string | null;
  readonly address?: BasicAddressInput | null;
}

export interface CustomerInput {
  readonly id?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly address?: AddressInput | null;
  readonly phone?: string | null;
  readonly email?: string | null;
  readonly motivation?: string | null;
  readonly utm?: UtmInput | null;
  readonly gender?: string | null;
  readonly needs?: CustomerNeedsInput | null;
  readonly password?: string | null;
  readonly landingPage?: string | null;
  readonly houses?: ReadonlyArray<(string | null)> | null;
  readonly business?: BusinessInput | null;
  readonly pipedriveId?: number | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly referrer?: string | null;
}

export interface CustomerNeedsInput {
  readonly budget?: number | null;
  readonly anticipatedSlurpers?: ReadonlyArray<(Slurper | null)> | null;
  readonly extraComfort?: boolean | null;
  readonly floor?: FloorNeedsInput | null;
  readonly heating?: HeatingNeedsInput | null;
  readonly windows?: WindowsNeedsInput | null;
  readonly solar?: SolarNeedsInput | null;
  readonly desiredLabel?: string | null;
  readonly expectedStay?: number | null;
  readonly motivations?: ReadonlyArray<(string | null)> | null;
  readonly cameFrom?: string | null;
}

export interface CustomerRegistrationBatchInput {
  readonly customer: CustomerInput;
  readonly dossier?: DossierInput | null;
  readonly dutyPackageRequest?: OperatorDutyPackageRequestInput | null;
}

export interface DateRangeInput {
  readonly start: any;
  readonly end: any;
}

export interface DealInput {
  readonly houseId?: string | null;
  readonly solution?: Solution | null;
  readonly salesManagerId?: string | null;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly icon?: string | null;
  readonly priority?: number | null;
  readonly pipedriveDealId?: number | null;
}

export interface DealItemInput {
  readonly productId: string;
  readonly amount: number;
  readonly retailPrice: number;
  readonly purchasePrice: number;
  readonly supplierId: string;
  readonly title: string;
  readonly category: ProductCategory;
}

export interface DealLostDetailsInput {
  readonly lostDate: any;
  readonly reason: string;
  readonly responsible: string;
  readonly comment: string;
}

export interface DiscountInput {
  readonly amount: number;
  readonly text: string;
  readonly type: DiscountType;
}

export interface DossierInput {
  readonly partnerAgentId: string;
  readonly customerFirstName?: string | null;
  readonly customerLastName?: string | null;
  readonly address: BasicAddressInput;
  readonly mortgageLender?: PartnerMortgageLender | null;
  readonly collective?: PartnerCollectiveKey | null;
  readonly houseId?: string | null;
  readonly hasGreenMortgage?: boolean | null;
}

export interface DurationInput {
  readonly length: number;
  readonly unit: DurationUnit;
}

export interface EPOLabelInput {
  readonly energyLabel: string;
  readonly energyIndex?: number | null;
  readonly energyIndexFlat?: number | null;
  readonly calculationType?: string | null;
  readonly buildingType?: string | null;
  readonly intakeDate?: any | null;
  readonly expirationDate?: any | null;
  readonly livingSpace?: number | null;
  readonly heatingNeed: number;
  readonly energyNeed?: number | null;
  readonly primaryFossilEnergy: number;
  readonly renewableEnergyRatio: number;
  readonly temperatureExceedance?: number | null;
  readonly temperatureExceedanceLimit?: number | null;
}

export interface ElectricsSituationInput {
  readonly availableFuseGroups?: number | null;
  readonly availablePowerSocket?: boolean | null;
  readonly distributorRequired?: string | null;
  readonly hasMainFuse?: boolean | null;
  readonly isWashingMachineOnSeparateFuseGroup?: string | null;
  readonly modemAccessibleFromInverter?: string | null;
  readonly modemLocation?: string | null;
  readonly transformerCurrent?: number | null;
  readonly transformerInternetAccess?: boolean | null;
  readonly transformerPhases?: number | null;
  readonly storageCapacity?: number | null;
  readonly availableFuseBoxCapacity?: number | null;
  readonly requiredChangesFuseBox?: string | null;
  readonly hasRCD?: boolean | null;
}

export interface EmailBodyInput {
  readonly operator?: OperatorEmailInput | null;
  readonly appointment?: AppointmentInput | null;
  readonly customer?: CustomerEmailInput | null;
  readonly houseId?: string | null;
  readonly orderId?: string | null;
  readonly installerId?: string | null;
  readonly adviserId?: string | null;
  readonly paid?: boolean | null;
  readonly quoteIds?: ReadonlyArray<string> | null;
  readonly showPayment?: boolean | null;
  readonly solutions?: ReadonlyArray<Solution> | null;
  readonly opts?: OptionsInput | null;
  readonly method?: string | null;
  readonly quotesReadyText?: string | null;
  readonly isGVADossier?: boolean | null;
  readonly forLeadGeneratorCustomer?: boolean | null;
  readonly isExtendedVisit?: boolean | null;
}

export interface EnergyDeltaInput {
  readonly gasFactor?: number | null;
  readonly electricityConsumption?: number | null;
  readonly electricityProduction?: number | null;
  readonly energyIndex?: number | null;
}

export interface EnergyPriceSituationInput {
  readonly electricity?: number | null;
  readonly gas?: number | null;
  readonly gasNetworkOperator?: number | null;
}

export interface EnergyPricesInput {
  readonly gas?: number | null;
  readonly electricity?: number | null;
  readonly discountedElectricity?: number | null;
}

export interface FloorAdviceTextSavingsInput {
  readonly meters: string;
  readonly moneySavings: string;
  readonly gasSavings: string;
  readonly investment: string;
  readonly paybackTime: string;
}

export interface FloorInput {
  readonly area?: number | null;
  readonly material?: string | null;
  readonly rc?: number | null;
  readonly rcBasedOnYearAndDecree?: boolean | null;
  readonly insulatableArea?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly insulatedArea?: number | null;
  readonly floorSystem?: string | null;
  readonly surfaces?: ReadonlyArray<(FloorSurfaceInput | null)> | null;
  readonly types?: ReadonlyArray<(string | null)> | null;
  readonly moisturePercentage?: number | null;
}

export interface FloorNeedsInput {
  readonly underfloorHeating?: boolean | null;
}

export interface FloorSituationInput {
  readonly annexInsulationRequired?: boolean | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly finish?: ReadonlyArray<string> | null;
  readonly musty?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly insulationLoosening?: boolean | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly solutionAdvised?: string | null;
}

export interface FloorSurfaceInput {
  readonly name: string;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly geometry?: GeometryInput | null;
}

export interface FullAddressInput {
  readonly zip: string;
  readonly number: number;
  readonly suffix?: string | null;
  readonly street: string;
  readonly city: string;
  readonly province?: string | null;
}

export interface FuseBoxDescriptorInput {
  readonly fuseBoxPhases: FuseBoxPhases;
}

export interface GenericProductDescriptorInput {
  readonly productId: string;
}

export interface GeometryInput {
  readonly type?: string | null;
  readonly coordinates?: ReadonlyArray<(CoordinatesInput | null)> | null;
}

export interface GoogleAnalyticsEventInput {
  readonly category: string;
  readonly action: string;
  readonly label?: string | null;
  readonly value?: number | null;
  readonly nonInteraction?: boolean | null;
  readonly transport?: string | null;
}

export interface HeatingAdviceTextSavingsInput {
  readonly moneySavings: string;
  readonly gasSavings: string;
  readonly percentageGasSavings: string;
  readonly increaseEnergyUsage: string;
  readonly investment: string;
  readonly paybackTime: string;
  readonly subsidy: string;
}

export interface HeatingInput {
  readonly type?: number | null;
  readonly boilerAge?: number | null;
  readonly heatEmissionSystems?: ReadonlyArray<(string | null)> | null;
  readonly installations?: ReadonlyArray<(string | null)> | null;
  readonly ventilationType?: number | null;
  readonly draught?: string | null;
  readonly pilotLight?: boolean | null;
  readonly heatPumpPipelineLength?: number | null;
  readonly floorHeatingGroups?: number | null;
  readonly floorHeatingSupplyTemperature?: number | null;
  readonly weatherStripping?: boolean | null;
}

export interface HeatingNeedsInput {
  readonly multiHotWater?: boolean | null;
  readonly newThermostat?: string | null;
  readonly boilerBrandPreference?: string | null;
  readonly extraWarmWaterCapacity?: boolean | null;
}

export interface HeatingSituationInput {
  readonly boilerBrand?: string | null;
  readonly boilerInstallationYear?: number | null;
  readonly currentBoilerLocation?: string | null;
  readonly heatPumpPipelineWorkRequired?: ReadonlyArray<string> | null;
  readonly scaffoldRequired?: boolean | null;
  readonly CW?: number | null;
  readonly flueGasOutlet?: string | null;
  readonly futureGasSlurpers?: ReadonlyArray<string> | null;
  readonly heatPumpBrand?: string | null;
  readonly newBoilerLocation?: string | null;
  readonly reasonNotAdvised?: string | null;
  readonly maintenanceContract?: boolean | null;
  readonly furtherInsulationPlanned?: boolean | null;
  readonly plannedInsulation?: ReadonlyArray<string> | null;
  readonly isCurrentThermostatSameBrand?: boolean | null;
  readonly availablePowerSocketForHeatPump?: boolean | null;
  readonly currentHeatingTemperature?: number | null;
  readonly boilerOrBufferTankAvailableWorkspace?: string | null;
  readonly heatPumpInsideUnitAvailableWorkspace?: string | null;
  readonly requiredItemsPlacingHeatPumpOutsideUnit?: string | null;
  readonly requiredItemsPlacingAirToAirHeatPumpOutsideUnit?: string | null;
  readonly heatPumpOutsideUnitDistanceToDrainage?: number | null;
  readonly pipeRouteHeatPumpOutsideToInsideUnit?: string | null;
  readonly pipeRouteAirToAirHeatPumpOutsideToInsideUnit?: string | null;
  readonly cableRouteHeatPumpOutsideToInsideUnit?: string | null;
  readonly isCookingWithGas?: boolean | null;
  readonly waterConsumption?: number | null;
  readonly expectedGasConsumptionAfterInsulation?: number | null;
  readonly suggestedHeatPumpBoilerLocation?: string | null;
  readonly bearingFloorMaterialAtBoiler?: string | null;
  readonly hasAsbestos?: boolean | null;
  readonly heatPumpOutsideUnitDistanceToFuseBox?: number | null;
  readonly airToAirHeatPumpOutsideUnitMountType?: string | null;
  readonly condensatePumpRequired?: string | null;
  readonly airToAirCustomerGoal?: string | null;
  readonly airToAirLocation?: string | null;
  readonly airToAirAmountOutsideUnits?: number | null;
  readonly airToAirPipeRouteLength?: number | null;
  readonly airToAirAmountRoomsWithInsideUnits?: number | null;
  readonly airToAirRoomSizes?: string | null;
  readonly underfloorHeatingArea?: number | null;
  readonly underfloorHeatingInstallationMethod?: string | null;
  readonly underfloorHeatingMillingFloorType?: ReadonlyArray<string> | null;
  readonly underfloorHeatingLevelingRequired?: string | null;
  readonly underfloorHeatingFinishRemoval?: string | null;
  readonly underfloorHeatingDistributorPowerSocket?: boolean | null;
  readonly underfloorHeatingMillingMachineUpstairs?: boolean | null;
}

export interface HomeBatterySituationInput {
  readonly advisedCapacity?: number | null;
  readonly cableRoute?: string | null;
  readonly cableRouteCableLength?: number | null;
  readonly spaceAround?: boolean | null;
  readonly needsFireResistantPlate?: boolean | null;
}

export interface InstallationInput {
  readonly id: string;
  readonly discount?: DiscountInput | null;
  readonly items: ReadonlyArray<InstallationItemInput>;
  readonly solution: Solution;
}

export interface InstallationItemInput {
  readonly productId: string;
  readonly amount: number;
  readonly retailPrice: number;
  readonly purchasePrice: number;
  readonly supplierId: string;
}

export interface InstallationMaterialDescriptorInput {
  readonly slantedRoofPanelAmount: number;
  readonly flatRoofPanelAmount: number;
}

export interface InstallationRegionInput {
  readonly from: number;
  readonly to: number;
}

export interface InstallerAgentInput {
  readonly firstName: string;
  readonly lastName: string;
  readonly companyId: string;
  readonly email: string;
  readonly phone: string;
  readonly consent: ReadonlyArray<ConsentInput>;
  readonly active: boolean;
  readonly roles: ReadonlyArray<InstallerAgentRole>;
}

export interface InstallerInput {
  readonly name?: string | null;
  readonly website?: string | null;
  readonly coc?: number | null;
  readonly iban?: string | null;
  readonly vat?: string | null;
  readonly email?: string | null;
  readonly inspectionEmail?: string | null;
  readonly installationEmail?: string | null;
  readonly contactPerson?: string | null;
  readonly phone?: string | null;
  readonly size?: number | null;
  readonly workRadius?: number | null;
  readonly address?: AddressInput | null;
  readonly certifications?: ReadonlyArray<string> | null;
  readonly solutions?: ReadonlyArray<Solution> | null;
  readonly pairedSupplierId?: string | null;
  readonly grippId?: number | null;
  readonly installationRegions?: ReadonlyArray<InstallationRegionInput> | null;
  readonly installationLeadTimes?: ReadonlyArray<LeadTimeInput> | null;
  readonly planningPeriod?: number | null;
  readonly customerNotificationPeriod?: number | null;
  readonly pipedriveId?: number | null;
  readonly legalName?: string | null;
  readonly rating?: number | null;
  readonly minimumRate?: number | null;
  readonly hasInstallerAccount?: boolean | null;
  readonly accountManagerId?: string | null;
  readonly slotsPerWeek?: number | null;
  readonly ptcRequirements?: ReadonlyArray<PTCRequirementsInput> | null;
  readonly contacts?: ReadonlyArray<ContactInput> | null;
}

export interface InstallerNotesInput {
  readonly general?: string | null;
  readonly pvSystem?: string | null;
  readonly wallInsulation?: string | null;
  readonly floorInsulation?: string | null;
  readonly crawlspaceInsulation?: string | null;
  readonly innerRoofInsulation?: string | null;
  readonly atticFloorInsulation?: string | null;
  readonly windowGlazing?: string | null;
  readonly windowFrames?: string | null;
  readonly centralHeatingBoiler?: string | null;
  readonly hybridHeatPump?: string | null;
  readonly electricHeatPump?: string | null;
  readonly airToAirHeatPump?: string | null;
  readonly underfloorHeating?: string | null;
  readonly chargingBox?: string | null;
  readonly homeBattery?: string | null;
  readonly pvSystemCableRoute?: string | null;
}

export interface InstallerStatusUpdateInput {
  readonly value: InstallerStatus;
  readonly reason?: InstallerStatusReason | null;
  readonly comment?: string | null;
}

export interface IntakeAppointmentMutationInput {
  readonly reason: string;
  readonly comment: string;
}

export interface IntakeIncompletionInput {
  readonly category: IntakeIncompletionReasonCategory;
  readonly description: string;
  readonly plannedCompletionDate?: any | null;
}

export interface IntakeInput {
  readonly id?: string | null;
  readonly houseId?: string | null;
  readonly operatorId?: string | null;
  readonly appliances?: AppliancesInput | null;
  readonly area?: number | null;
  readonly conservatory?: boolean | null;
  readonly constructionYear?: number | null;
  readonly crawlspace?: CrawlspaceInput | null;
  readonly floor?: FloorInput | null;
  readonly hasAnnex?: boolean | null;
  readonly heating?: HeatingInput | null;
  readonly monument?: boolean | null;
  readonly notes?: NotesInput | null;
  readonly pvSystemPower?: number | null;
  readonly residents?: number | null;
  readonly roof?: RoofInput | null;
  readonly started?: any | null;
  readonly stories?: number | null;
  readonly type?: number | null;
  readonly typeId?: number | null;
  readonly vve?: boolean | null;
  readonly walls?: WallsInput | null;
  readonly windows?: WindowsInput | null;
  readonly woz?: number | null;
  readonly hasBasement?: boolean | null;
}

export interface InverterDescriptorInput {
  readonly fuseBoxPhases: FuseBoxPhases;
  readonly panelAmount: number;
  readonly needsOptimizers: boolean;
  readonly inverterType: InverterType;
  readonly roofSurfaceAmount: number;
}

export interface JobInput {
  readonly id?: string | null;
  readonly houseId?: string | null;
  readonly quoteId?: string | null;
  readonly installerId: string;
  readonly cancellationId?: string | null;
  readonly comment?: string | null;
  readonly commentsForInstaller?: string | null;
  readonly pdfLink?: string | null;
}

export interface JobItemInput {
  readonly productId: string;
  readonly amount: number;
  readonly retailPrice: number;
  readonly purchasePrice: number;
  readonly supplierId: string;
}

export interface JobPlanningUpdateRequestInput {
  readonly jobId: string;
  readonly requestedPlannedForDate: any;
  readonly arrivalWindow: number;
  readonly reason: JobReplanningReason;
  readonly comment: string;
}

export interface JourneyEventInput {
  readonly houseId?: string | null;
  readonly utm?: UtmInput | null;
  readonly date?: any | null;
  readonly pageViews?: ReadonlyArray<string> | null;
  readonly referrer?: string | null;
  readonly entityId?: string | null;
  readonly entityType?: string | null;
}

export interface LaborCostDescriptorInput {
  readonly panelAmount: number;
  readonly inverterType: InverterType;
}

export interface LeadTimeInput {
  readonly solution: Solution;
  readonly brandId?: string | null;
  readonly minDuration: number;
  readonly maxDuration: number;
  readonly note?: string | null;
}

export interface ModifyOrderInput {
  readonly dutyPackage: string;
  readonly extraDuties?: ReadonlyArray<DutyName> | null;
  readonly extraDutyPackages?: ReadonlyArray<string> | null;
  readonly voucherCode?: string | null;
  readonly sendSupplementalDutyPackageMail?: boolean | null;
}

export interface NotesInput {
  readonly general?: string | null;
  readonly pvSystem?: string | null;
  readonly walls?: string | null;
  readonly floor?: string | null;
  readonly roof?: string | null;
  readonly heating?: string | null;
  readonly insulationGlazing?: string | null;
}

export interface OperatorDutyPackageRequestInput {
  readonly dutyPackage: string;
  readonly appointment: AppointmentInput;
  readonly phone: string;
  readonly extraDuties: ReadonlyArray<DutyName>;
  readonly isPaid?: boolean | null;
  readonly sendMail: boolean;
  readonly voucherCode?: string | null;
  readonly notes?: string | null;
}

export interface OperatorEmailInput {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
}

export interface OperatorInput {
  readonly email?: string | null;
  readonly password?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly gender?: string | null;
  readonly address?: AddressInput | null;
  readonly phone?: string | null;
  readonly jobTitle?: string | null;
  readonly role?: string | null;
  readonly team?: string | null;
  readonly isFreelancer?: boolean | null;
  readonly isActive?: boolean | null;
  readonly pipedriveId?: number | null;
  readonly workRegions?: ReadonlyArray<InstallationRegionInput> | null;
  readonly salesManagerId?: string | null;
}

export interface OptionsInput {
  readonly bcc?: ReadonlyArray<string> | null;
}

export interface PTCRequirementsInput {
  readonly required: boolean;
  readonly solution: Solution;
}

export interface PaginationInput {
  readonly offset?: number | null;
  readonly limit?: number | null;
  readonly order?: string | null;
  readonly field?: string | null;
}

export interface PanelDescriptorInput {
  readonly panelType: PanelType;
}

export interface PartnerCompanyInput {
  readonly name: string;
  readonly phone?: string | null;
}

export interface PartnerOfficeInput {
  readonly address?: BasicAddressInput | null;
  readonly phone?: string | null;
  readonly name?: string | null;
  readonly companyId?: string | null;
}

export interface PointInput {
  readonly x: number;
  readonly y: number;
}

export interface PriceInput {
  readonly id?: string | null;
  readonly purchasePrice: number;
  readonly retailPrice: number;
  readonly availability?: PriceAvailability | null;
  readonly supplierId: string;
  readonly productId: string;
  readonly grippId?: number | null;
  readonly minimumInstallerRate?: number | null;
}

export interface ProductInput {
  readonly advantages?: ReadonlyArray<string> | null;
  readonly archived?: any | null;
  readonly backsheet?: string | null;
  readonly backsheetColor?: Color | null;
  readonly brandId?: string | null;
  readonly capacity?: number | null;
  readonly category?: ProductCategory | null;
  readonly cellType?: PvCellType | null;
  readonly color?: Color | null;
  readonly combiBoiler?: boolean | null;
  readonly communicationProtocol?: CommunicationProtocol | null;
  readonly connectionType?: ConnectionType | null;
  readonly countryOfOrigin?: string | null;
  readonly customMarkup?: number | null;
  readonly cwClass?: number | null;
  readonly depth?: number | null;
  readonly description?: string | null;
  readonly disadvantages?: ReadonlyArray<string> | null;
  readonly efficiency?: number | null;
  readonly energyLabel?: EnergyLabel | null;
  readonly externalUnitNoise?: number | null;
  readonly extraWarrantyAvailable?: boolean | null;
  readonly flowRate?: number | null;
  readonly frameColor?: Color | null;
  readonly glassType?: GlassType | null;
  readonly groups?: number | null;
  readonly heatingSettings?: HeatingSetting | null;
  readonly heatingSystemType?: HeatingSystemType | null;
  readonly heatPumpType?: HeatPumpType | null;
  readonly height?: number | null;
  readonly internalDescription?: string | null;
  readonly isdeCode?: string | null;
  readonly isPresentedOnQuote?: boolean | null;
  readonly mainsConnectionCurrentRequired?: number | null;
  readonly material?: string | null;
  readonly maxElectricityConsumption?: number | null;
  readonly maxFlowTemperature?: number | null;
  readonly maxOperatingCurrent?: number | null;
  readonly maxOutputPower?: number | null;
  readonly minConnectionLoad?: ConnectionLoadType | null;
  readonly minOutdoorTemperature?: number | null;
  readonly model?: string | null;
  readonly monitoring?: InverterMonitoring | null;
  readonly materials?: ReadonlyArray<string> | null;
  readonly necessaryFuses?: string | null;
  readonly noise?: number | null;
  readonly openThermSupport?: boolean | null;
  readonly paintable?: boolean | null;
  readonly panelAmount?: number | null;
  readonly panelType?: PvPanelType | null;
  readonly phases?: number | null;
  readonly placement?: string | null;
  readonly pMax?: number | null;
  readonly power?: number | null;
  readonly priceUnit?: PriceUnit | null;
  readonly productWarranty?: number | null;
  readonly programmable?: boolean | null;
  readonly residualCurrentDevices?: number | null;
  readonly rd?: number | null;
  readonly releaseDate?: string | null;
  readonly refrigerant?: RefrigerantType | null;
  readonly refrigerantGWP?: number | null;
  readonly scop35?: number | null;
  readonly scop55?: number | null;
  readonly series?: string | null;
  readonly sizeInternalUnit?: string | null;
  readonly sizeExternalUnit?: string | null;
  readonly smartphoneApp?: boolean | null;
  readonly solution?: Solution | null;
  readonly splitUnit?: SplitUnitType | null;
  readonly strings?: number | null;
  readonly subsidy?: number | null;
  readonly tax?: number | null;
  readonly thickness?: number | null;
  readonly title?: string | null;
  readonly tier?: ProductTier | null;
  readonly u?: number | null;
  readonly unitsPerHour?: number | null;
  readonly warrantedPMax?: number | null;
  readonly weight?: number | null;
  readonly weightExternalUnit?: number | null;
  readonly width?: number | null;
  readonly wireless?: boolean | null;
  readonly youTubeId?: string | null;
  readonly zta?: number | null;
  readonly panelId?: string | null;
  readonly inverterId?: string | null;
  readonly optimizerId?: string | null;
  readonly installationMaterialId?: string | null;
  readonly laborId?: string | null;
}

export interface PvSystemInfoInput {
  readonly cardinalDirection: string;
  readonly multipleRoofSurfaces: boolean;
  readonly installationPlanProperties: string;
  readonly neighbourDiscount: boolean;
  readonly panelPMax: string;
  readonly panelCellType: string;
  readonly panelBrand: string;
  readonly inverterBrand: string;
  readonly withOptimizer: boolean;
  readonly panelAmount: string;
  readonly electricityAmount: string;
  readonly electricityPercentage: string;
  readonly moneySavings: string;
  readonly investment: string;
  readonly paybackTime: string;
}

export interface QuoteDescriptorInputPVSystem {
  readonly panelType: PanelType;
  readonly inverterType: InverterType;
  readonly inverterOptimizers: boolean;
  readonly fuseBoxPhases: FuseBoxPhases;
  readonly slantedRoofPanelAmount: number;
  readonly flatRoofPanelAmount: number;
  readonly fuseBoxProduct?: boolean | null;
  readonly cherryPickerProduct?: boolean | null;
  readonly roofSurfaceAmount: number;
  readonly circuitBreakerProduct?: boolean | null;
  readonly roofDuctProduct?: boolean | null;
  readonly concreteDrillingProduct?: boolean | null;
  readonly ladderLiftProduct?: boolean | null;
  readonly ptcProduct?: boolean | null;
}

export interface QuoteDraftGenericProductDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: GenericProductDescriptorInput;
}

export interface QuoteInput {
  readonly houseId?: string | null;
  readonly items?: ReadonlyArray<InstallationItemInput> | null;
  readonly installationId?: string | null;
  readonly pipedriveDealId?: string | null;
  readonly final?: any | null;
  readonly cancelled?: any | null;
  readonly accepted?: any | null;
  readonly acceptedSubjectToFunding?: AcceptedSubjectToFundingInput | null;
  readonly reference?: string | null;
  readonly expirationDate?: any | null;
  readonly energyDelta?: EnergyDeltaInput | null;
  readonly text?: string | null;
  readonly discount?: DiscountInput | null;
  readonly sent?: any | null;
  readonly solution?: Solution | null;
  readonly pvSystemInfo?: QuotePvSystemInfoInput | null;
  readonly cancellationInformation?: CancellationInformationInput | null;
}

export interface QuotePVDraftCircuitBreakerDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: CircuitBreakerDescriptorInput;
}

export interface QuotePVDraftFuseBoxDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: FuseBoxDescriptorInput;
}

export interface QuotePVDraftInput {
  readonly panelDescriptorItem: QuotePVDraftPanelDescriptorItemInput;
  readonly inverterDescriptorItem: QuotePVDraftInverterDescriptorItemInput;
  readonly installationMaterialDescriptorItem: QuotePVDraftInstallationMaterialDescriptorItemInput;
  readonly laborCostDescriptorItem: QuotePVDraftLaborCostDescriptorItemInput;
  readonly fuseBoxDescriptorItem?: QuotePVDraftFuseBoxDescriptorItemInput | null;
  readonly cherryPickerDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
  readonly extraSurfaceDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
  readonly circuitBreakerProductDescriptorItem?: QuotePVDraftCircuitBreakerDescriptorItemInput | null;
  readonly roofDuctProductDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
  readonly concreteDrillProductDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
  readonly ladderLiftProductDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
  readonly ptcProductDescriptorItem?: QuoteDraftGenericProductDescriptorItemInput | null;
}

export interface QuotePVDraftInstallationMaterialDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: InstallationMaterialDescriptorInput;
}

export interface QuotePVDraftInverterDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: InverterDescriptorInput;
}

export interface QuotePVDraftLaborCostDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: LaborCostDescriptorInput;
}

export interface QuotePVDraftPanelDescriptorItemInput {
  readonly amount: number;
  readonly productDescriptor: PanelDescriptorInput;
}

export interface QuotePvSystemInfoInput {
  readonly cardinalDirection?: string | null;
  readonly multipleRoofSurfaces?: boolean | null;
  readonly installationPlanProperties?: string | null;
  readonly neighbourDiscount?: boolean | null;
  readonly hasElectricityStorage?: boolean | null;
}

export interface QuoteTemplateInput {
  readonly solution: Solution;
  readonly title: string;
  readonly description?: string | null;
  readonly isActive: boolean;
  readonly supplierId: string;
  readonly items: ReadonlyArray<QuoteTemplateItemInput>;
}

export interface QuoteTemplateItemInput {
  readonly productId: string;
  readonly amount: number;
  readonly supplierId?: string | null;
}

export interface RatingInput {
  readonly work?: number | null;
  readonly communication?: number | null;
  readonly extraWork?: number | null;
  readonly cleanliness?: number | null;
  readonly friendliness?: number | null;
  readonly professionalism?: number | null;
  readonly punctuality?: number | null;
  readonly serviceRating?: number | null;
  readonly improvements?: string | null;
  readonly comment?: string | null;
  readonly notesOnDEB?: string | null;
  readonly notesOnInstaller?: string | null;
}

export interface ReplanningMetaDataInput {
  readonly comment: JobReplanningReason;
  readonly additionalInformation?: string | null;
}

export interface RoofAdviceTextSavingsInput {
  readonly meters: string;
  readonly moneySavings: string;
  readonly gasSavings: string;
  readonly investment: string;
  readonly paybackTime: string;
}

export interface RoofInput {
  readonly area?: number | null;
  readonly azimuth?: number | null;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly roofing?: ReadonlyArray<(string | null)> | null;
  readonly shape?: ReadonlyArray<(string | null)> | null;
  readonly surfaces?: ReadonlyArray<(RoofSurfaceInput | null)> | null;
  readonly ridgeHeight?: number | null;
  readonly insulatableInnerRoofArea?: number | null;
  readonly insulatableAtticFloorArea?: number | null;
  readonly atticFloorInsulationDepth?: number | null;
  readonly insulatedArea?: number | null;
  readonly trussWidth?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly inclination?: number | null;
  readonly atticFloorArea?: number | null;
}

export interface RoofSituationInput {
  readonly advisedAtticFloorInsulationThickness?: number | null;
  readonly advisedInnerRoofInsulationProduct?: string | null;
  readonly amountKneeWall?: number | null;
  readonly amountPartitionWall?: number | null;
  readonly amountWindowsInTargetArea?: number | null;
  readonly atticIsLivingSpace?: boolean | null;
  readonly coverRequired?: ReadonlyArray<string> | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly hasAsbestosInRoofing?: string | null;
  readonly outsideFinishState?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly possibleInsulationLocations?: ReadonlyArray<string> | null;
  readonly purlinDistance?: number | null;
  readonly raisable?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly requiredInsulationMethod?: string | null;
  readonly sandwichPanelRequired?: string | null;
  readonly spareTiles?: boolean | null;
  readonly state?: string | null;
  readonly targetAreaAccess?: string | null;
  readonly targetAreaWorkable?: string | null;
  readonly tileLength?: number | null;
  readonly tileWidth?: number | null;
  readonly hasSurfaceObstacles?: boolean | null;
  readonly isCommonRafter?: boolean | null;
  readonly isVaporProof?: boolean | null;
  readonly maxWorkingHeight?: number | null;
  readonly amountRoomsToBeInsulated?: number | null;
  readonly hasFlooring?: boolean | null;
  readonly hasAtticFloorInsulation?: boolean | null;
  readonly currentAtticInsulationMaterial?: string | null;
  readonly atticFloorArea?: number | null;
  readonly purlinDepth?: number | null;
  readonly canMeasureTiles?: boolean | null;
}

export interface RoofSurfaceInput {
  readonly name: string;
  readonly type?: string | null;
  readonly rc?: number | null;
  readonly geometry?: GeometryInput | null;
  readonly tilt?: number | null;
}

export interface SedumInput {
  readonly surface?: string | null;
  readonly surfaceAngle?: number | null;
  readonly area?: number | null;
  readonly lossArea?: number | null;
  readonly roofingAge?: number | null;
  readonly eavesProfileAmount?: number | null;
  readonly carryingCapacity?: number | null;
  readonly hasGravel?: boolean | null;
  readonly roofHeight?: number | null;
  readonly accessibility?: string | null;
  readonly preferredType?: string | null;
  readonly gravelEdge?: boolean | null;
}

export interface SituationInput {
  readonly houseId?: string | null;
  readonly consumption?: ConsumptionInput | null;
  readonly consumptionAvailable?: string | null;
  readonly crawlspace?: CrawlspaceSituationInput | null;
  readonly currentOccupant?: boolean | null;
  readonly currentSlurpers?: ReadonlyArray<(Slurper | null)> | null;
  readonly electrics?: ElectricsSituationInput | null;
  readonly energyBill?: number | null;
  readonly parkingAvailable?: boolean | null;
  readonly energyPrices?: EnergyPriceSituationInput | null;
  readonly floor?: FloorSituationInput | null;
  readonly heating?: HeatingSituationInput | null;
  readonly installerNotes?: InstallerNotesInput | null;
  readonly roof?: RoofSituationInput | null;
  readonly slurperConsumption?: number | null;
  readonly solar?: SolarSituationInput | null;
  readonly walls?: WallSituationInput | null;
  readonly windows?: WindowsSituationInput | null;
  readonly chargingBox?: ChargingBoxInput | null;
  readonly homeBattery?: HomeBatterySituationInput | null;
  readonly sedum?: SedumInput | null;
}

export interface SolarNeedsInput {
  readonly pvPanelPreferences?: ReadonlyArray<string> | null;
  readonly pvRoofPreferences?: ReadonlyArray<string> | null;
}

export interface SolarSituationInput {
  readonly cableRoute?: string | null;
  readonly currentSystem?: CurrentSystemInput | null;
  readonly inverterLocation?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly reasonNotAdvised?: string | null;
  readonly roofShading?: boolean | null;
  readonly accessibleThroughBack?: boolean | null;
  readonly amountUsableSurfaces?: number | null;
  readonly cableRouteCableLength?: number | null;
  readonly cableRouteNotes?: string | null;
  readonly hasAsbestosInRoofing?: string | null;
}

export interface SolutionAdviceInput {
  readonly advisedQuoteIds?: ReadonlyArray<string> | null;
  readonly text?: string | null;
}

export interface SupplierInput {
  readonly name: string;
  readonly website?: string | null;
  readonly coc?: number | null;
  readonly iban?: string | null;
  readonly vat?: string | null;
  readonly email?: string | null;
  readonly phone?: string | null;
  readonly address?: AddressInput | null;
  readonly solutions?: ReadonlyArray<Solution> | null;
  readonly pairedInstallerId?: string | null;
  readonly grippId?: number | null;
  readonly legalName?: string | null;
}

export interface TestEmailVariables {
  readonly operator: OperatorEmailInput;
  readonly appointment?: AppointmentInput | null;
  readonly customer?: CustomerEmailInput | null;
  readonly houseId?: string | null;
  readonly orderId?: string | null;
  readonly jobId?: string | null;
  readonly adviserId?: string | null;
  readonly quoteId?: string | null;
  readonly quoteIds?: ReadonlyArray<string> | null;
  readonly solutions?: ReadonlyArray<Solution> | null;
  readonly quotesReadyText?: string | null;
  readonly electricityStoragePresent?: string | null;
  readonly installationDate?: string | null;
  readonly jobFollowUpType?: JobFollowUpType | null;
}

export interface UpdatePartnerAgentInput {
  readonly gender?: string | null;
  readonly email?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly phone?: string | null;
  readonly consent?: ReadonlyArray<ConsentInput> | null;
  readonly shared?: boolean | null;
  readonly role?: PartnerAgentRole | null;
  readonly officeId?: string | null;
}

export interface UtmInput {
  readonly campaign?: string | null;
  readonly source?: string | null;
  readonly medium?: string | null;
  readonly term?: string | null;
  readonly content?: string | null;
}

export interface WallSituationInput {
  readonly amountCavityBrush?: number | null;
  readonly annexCoversOriginalWall?: boolean | null;
  readonly annexInsulationRequired?: boolean | null;
  readonly cavityWidth?: number | null;
  readonly currentInsulation?: CurrentInsulationInput | null;
  readonly drillDiameter?: number | null;
  readonly hasVentilationGrilles?: boolean | null;
  readonly hasWildlife?: boolean | null;
  readonly highestPointToBeInsulated?: number | null;
  readonly paint?: string | null;
  readonly permissionVVE?: boolean | null;
  readonly platformRequired?: string | null;
  readonly reasonNotAdvised?: string | null;
  readonly rubbish?: boolean | null;
  readonly sidesToBeInsulated?: ReadonlyArray<string> | null;
  readonly solutionAdvised?: string | null;
  readonly vegetation?: string | null;
  readonly ventilationGrillesRequired?: number | null;
  readonly wallsAccessible?: boolean | null;
  readonly seamState?: string | null;
  readonly moistureSpots?: boolean | null;
  readonly impregnableArea?: number | null;
  readonly wallImpregnationDesired?: boolean | null;
}

export interface WallSurfaceInput {
  readonly name: string;
  readonly type?: WallSurfaceType | null;
  readonly rc?: number | null;
  readonly cavityWidth?: number | null;
  readonly jointSize?: number | null;
  readonly gutterHeight?: number | null;
  readonly geometry?: GeometryInput | null;
}

export interface WallsInput {
  readonly amount?: number | null;
  readonly area?: number | null;
  readonly rc?: number | null;
  readonly insulatableArea?: number | null;
  readonly insulatedArea?: number | null;
  readonly gutterHeight?: number | null;
  readonly nonInsulatableArea?: number | null;
  readonly surfaces?: ReadonlyArray<(WallSurfaceInput | null)> | null;
  readonly connectedGarage?: boolean | null;
  readonly cavity?: boolean | null;
  readonly cavityWidth?: number | null;
}

export interface WeeklyCapacityInput {
  readonly date: any;
  readonly capacity: number;
}

export interface WeeklyInstallationCapacityInput {
  readonly solution: Solution;
  readonly weeks: ReadonlyArray<WeeklyCapacityInput>;
}

export interface WindowsAdviceTextSavingsInput {
  readonly meters: string;
  readonly moneySavings: string;
  readonly gasSavings: string;
  readonly investment: string;
  readonly paybackTime: string;
}

export interface WindowsInput {
  readonly space?: SpaceType | null;
  readonly type?: GlassType | null;
  readonly totalSingleArea?: number | null;
  readonly totalDoubleArea?: number | null;
  readonly totalHRArea?: number | null;
}

export interface WindowsNeedsInput {
  readonly soundproofing?: boolean | null;
  readonly newFrames?: boolean | null;
}

export interface WindowsSituationInput {
  readonly amountRequiringReplacement?: number | null;
  readonly areaRequiringReplacementHr?: number | null;
  readonly areaRequiringReplacementSafety?: number | null;
  readonly amountRequiringReplacementHr?: number | null;
  readonly amountRequiringReplacementSafety?: number | null;
  readonly currentWindowFinish?: string | null;
  readonly floorsRequiringReplacement?: ReadonlyArray<number> | null;
  readonly frameCostExplained?: boolean | null;
  readonly hasOldGlazing?: boolean | null;
  readonly largestRequiringReplacement?: number | null;
  readonly leakyWindows?: number | null;
  readonly reasonNotAdvised?: string | null;
  readonly state?: string | null;
  readonly advisedAmountVentilationGrilles?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
