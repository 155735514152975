import { gql } from '@apollo/client';
import { CompanyFragment, InstallationFragment, JobFragment, QuoteFragment } from '../fragments';
import { QuoteJobFragment } from '../fragments/Job';

export const GET_DEALS = gql`
  query getDeals($houseId: ID!, $solution: Solution) {
    dealsByHouseSolution(houseId: $houseId, solution: $solution) {
      id
      reference
      quotes {
        id
      }
      jobs {
        id
      }
    }
  }
`;

export const INSTALLATION_BY_HOUSE_SOLUTION = gql`
  ${InstallationFragment}
  query installationByHouseSolution($houseId: ID!, $solution: Solution!) {
    installationByHouseSolution(houseId: $houseId, solution: $solution) {
      ...Installation
    }
  }
`;

export const GENERATE_INSTALLATION = gql`
  ${InstallationFragment}
  mutation generateInstallation($houseId: ID!, $solution: Solution) {
    generateInstallation(houseId: $houseId, solution: $solution) {
      ...Installation
    }
  }
`;

export const CREATE_INSTALLATION = gql`
  ${InstallationFragment}
  mutation createInstallation($houseId: ID!, $solution: Solution) {
    createInstallation(houseId: $houseId, solution: $solution) {
      ...Installation
    }
  }
`;

export const GENERATE_QUOTE = gql`
  ${QuoteFragment}
  mutation generateQuoteFromInstallation(
    $installationId: ID!
    $operatorId: ID!
    $pvSystemInfo: QuotePvSystemInfoInput
    $dealReference: String
    $quoteToCopyId: ID
    $ensureValidPurchasePrices: Boolean!
    $experiments: [QuoteExperiment!]
  ) {
    generateQuoteFromInstallation(
      installationId: $installationId
      operatorId: $operatorId
      pvSystemInfo: $pvSystemInfo
      dealReference: $dealReference
      quoteToCopyId: $quoteToCopyId
      ensureValidPurchasePrices: $ensureValidPurchasePrices
      experiments: $experiments
    ) {
      ...Quote
      isSuppliable
      deal {
        id
        reference
        pipedriveDealId
        created
        onHold {
          since
          reason
        }
        status
        solution
        noLongerActive {
          lostDate
          reason
        }
        salesManagerId
        pipedriveDealId
      }
    }
  }
`;

export const QUOTE_TO_INSTALLATION = gql`
  ${InstallationFragment}
  mutation quoteToInstallation($installationId: ID!, $quoteId: ID!) {
    quoteToInstallation(installationId: $installationId, quoteId: $quoteId) {
      ...Installation
    }
  }
`;

export const QUOTES_BY_SOLUTION = gql`
  ${QuoteFragment}
  query quotesBySolution($houseId: ID!, $solution: Solution!) {
    quotesBySolution(houseId: $houseId, solution: $solution) {
      ...Quote
      deal {
        id
        noLongerActive {
          lostDate
          reason
        }
      }
      isSuppliable
    }
  }
`;

export const DEALS_BY_SOLUTION_DOMAIN = gql`
  ${QuoteFragment}
  query dealsByHouseSolutionDomain($houseId: ID!, $solutionDomain: String!) {
    dealsByHouseSolutionDomain(houseId: $houseId, solutionDomain: $solutionDomain) {
      id
      reference
      created
      solution
      pipedriveDealUrl
      isInstalled
      status
      onHold {
        since
        reason
      }
      noLongerActive {
        lostDate
        reason
      }
      activeQuote {
        id
        isAccepted
        isCancelled
        isExpired
      }
      pipedriveDealId
      quotes {
        ...Quote
        isSuppliable
        jobs {
          id
          type
          planningStatus
          status
          completed
        }
      }
      items {
        amount
        product {
          id
        }
        supplier {
          id
        }
      }
      salesManagerId
      title
      description
      icon
      priority
    }
  }
`;

export const DEALS_BY_SOLUTION = gql`
  ${QuoteFragment}
  query dealsByHouseSolution($houseId: ID!, $solution: Solution!) {
    dealsByHouseSolution(houseId: $houseId, solution: $solution) {
      id
      reference
      created
      solution
      isInstalled
      pipedriveDealUrl
      salesManagerId
      status
      onHold {
        since
        reason
        time
      }
      noLongerActive {
        lostDate
        reason
      }
      pipedriveDealId
      activeQuote {
        id
        isAccepted
        isCancelled
        isExpired
      }
      quotes {
        ...Quote
        isSuppliable
        jobs {
          id
          type
          status
          planningStatus
          completed
        }
      }
      items {
        product {
          id
        }
        supplier {
          id
        }
        amount
      }
      title
      description
      icon
      priority
    }
  }
`;

export const CREATE_JOB_BY_TYPE = gql`
  ${QuoteJobFragment}
  mutation generateJobFromQuoteByType($quoteId: ID!, $type: JobType!) {
    generateJobFromQuoteByType(quoteId: $quoteId, type: $type) {
      ...QuoteJob
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: ID!) {
    deleteJob(id: $id)
  }
`;

export const JOBS_BY_QUOTE = gql`
  ${JobFragment}
  query jobsByQuote($quoteId: ID!) {
    jobsByQuote(quoteId: $quoteId) {
      ...Job
      planningStatus
      installerSignoff {
        jobId
        type
        creatorId
        created
      }
      proposals {
        created
        expirationDate
        fulfilled {
          date
          key
        }
        options {
          date
          key
        }
      }
      mostRecentlySentPlanning {
        sent
      }
      currentPlanning {
        created
        sent
        plannedOn
        plannedFor
        suspended
      }
      plannings {
        created
        sent
        plannedOn
        plannedFor
        suspended
      }
    }
  }
`;

export const CUSTOMER_EMAIL_BY_HOUSE = gql`
  query customerEmailByHouse($houseId: ID!) {
    house(id: $houseId) {
      customer {
        id
        email
      }
    }
  }
`;

export const GET_INSTALLER = gql`
  query installerById($id: ID!) {
    installerById(id: $id) {
      id
      name
    }
  }
`;

export const UPDATE_JOB_INSTALLER = gql`
  ${JobFragment}
  mutation updateJobInstaller($jobId: ID!, $installerId: ID!) {
    updateJobInstaller(jobId: $jobId, installerId: $installerId) {
      ...Job
    }
  }
`;

export const UPDATE_COMMENTS_FOR_INSTALLER = gql`
  mutation updateCommentsForInstaller($jobId: ID!, $commentsForInstaller: String!) {
    updateCommentsForInstaller(jobId: $jobId, commentsForInstaller: $commentsForInstaller) {
      commentsForInstaller
    }
  }
`;

export const UPDATE_QUOTE_VALIDITY = gql`
  mutation updateQuoteValidity($quoteId: ID!, $date: Date!) {
    updateQuoteValidity(quoteId: $quoteId, date: $date) {
      id
      expirationDate
    }
  }
`;

export const QUOTES_BY_HOUSE = gql`
  ${QuoteFragment}
  query quotesByHouse($houseId: ID!) {
    house(id: $houseId) {
      id
      quotes {
        ...Quote
      }
    }
  }
`;

export const GET_PRICE_AVAILABILITY = gql`
  query checkPriceAvailability($productId: ID!, $supplierId: ID!) {
    checkPriceAvailability(productId: $productId, supplierId: $supplierId)
  }
`;

export const SUPPLIER_BY_ID = gql`
  ${CompanyFragment}
  query supplierById($id: ID!) {
    supplierById(id: $id) {
      id
      ...Company
    }
  }
`;

export const UPDATE_INSTALLATION = gql`
  ${InstallationFragment}
  mutation updateInstallation($id: ID!, $installation: InstallationInput!) {
    updateInstallation(id: $id, installation: $installation) {
      id
      ...Installation
    }
  }
`;

export const ADD_ITEM_TO_INSTALLATION = gql`
  ${InstallationFragment}
  mutation addProductToInstallation($id: ID!, $item: InstallationItemInput!) {
    addProductToInstallation(id: $id, item: $item) {
      id
      ...Installation
    }
  }
`;

export const DELETE_INSTALLATION_ITEM = gql`
  ${InstallationFragment}
  mutation deleteItem($installationId: ID!, $productId: ID) {
    deleteItem(installationId: $installationId, productId: $productId) {
      id
      ...Installation
    }
  }
`;

export const UPDATE_DISCOUNT = gql`
  ${InstallationFragment}
  mutation updateDiscount($installationId: ID!, $discount: DiscountInput) {
    updateDiscount(installationId: $installationId, discount: $discount) {
      id
      ...Installation
    }
  }
`;

export const UPDATE_QUOTE = gql`
  ${QuoteFragment}
  mutation updateQuote($id: ID!, $quote: QuoteInput!) {
    updateQuote(id: $id, quote: $quote) {
      ...Quote
    }
  }
`;

export const UPDATE_QUOTE_DEAL = gql`
  ${QuoteFragment}
  mutation updateQuoteDeal($id: ID!, $dealId: ID!) {
    updateQuoteDeal(id: $id, dealId: $dealId) {
      ...Quote
    }
  }
`;

export const UPDATE_JOB = gql`
  ${JobFragment}
  mutation updateJob($id: ID!, $job: JobInput!) {
    updateJob(id: $id, job: $job) {
      ...Job
    }
  }
`;

export const ACCEPT_QUOTE = gql`
  ${QuoteFragment}
  mutation acceptQuote(
    $id: ID!
    $skipCustomerEmail: Boolean!
    $acceptType: String!
    $journeyData: JourneyEventInput
    $acceptedBy: String
  ) {
    acceptQuote(
      id: $id
      skipCustomerEmail: $skipCustomerEmail
      acceptType: $acceptType
      journeyData: $journeyData
      acceptedBy: $acceptedBy
    ) {
      ...Quote
      deal {
        id
        pipedriveDealId
        noLongerActive {
          lostDate
          reason
        }
      }
    }
  }
`;

export const ACCEPT_D2C_QUOTE = gql`
  ${QuoteFragment}
  mutation acceptD2CQuote($quoteId: ID!, $acceptType: String) {
    acceptD2CQuote(quoteId: $quoteId, acceptType: $acceptType) {
      ...Quote
      deal {
        id
        pipedriveDealId
        noLongerActive {
          lostDate
          reason
        }
      }
    }
  }
`;

export const ACCEPT_QUOTE_SUBJECT_TO_FUNDING = gql`
  mutation acceptSubjectToFunding(
    $quoteId: ID!
    $dateFundingExpected: Date!
    $acceptType: String!
    $skipCustomerEmail: Boolean
  ) {
    acceptQuoteSubjectToFunding(
      quoteId: $quoteId
      dateFundingExpected: $dateFundingExpected
      acceptType: $acceptType
      skipCustomerEmail: $skipCustomerEmail
    ) {
      id
      acceptedSubjectToFunding {
        date
        dateFundingExpected
      }
    }
  }
`;

export const SEND_QUOTE = gql`
  ${QuoteFragment}
  mutation sendQuote($id: ID!, $updateCalculations: Boolean!) {
    sendQuote(id: $id, updateCalculations: $updateCalculations) {
      ...Quote
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation deleteQuote($id: ID!) {
    deleteQuote(id: $id)
  }
`;

export const SAVE_COMMENT = gql`
  ${JobFragment}
  mutation saveComment($jobId: ID!, $content: String) {
    saveComment(jobId: $jobId, content: $content) {
      ...Job
    }
  }
`;

export const SEND_JOB = gql`
  mutation sendJob(
    $jobId: ID!
    $operatorName: String!
    $sendToCustomer: Boolean!
    $sendToInstaller: Boolean!
    $installerSelectionReason: String
  ) {
    sendJob(
      jobId: $jobId
      operatorName: $operatorName
      sendToCustomer: $sendToCustomer
      sendToInstaller: $sendToInstaller
      installerSelectionReason: $installerSelectionReason
    ) {
      customerEmailSent
      installerEmailSent
      job {
        id
        projectGrippId
        purchaseOrderGrippId
        sentOn
        isSent
        mostRecentlySentPlanning {
          sent
        }
        currentPlanning {
          sent
          plannedOn
          plannedFor
          suspended
        }
        plannings {
          created
          sent
          plannedOn
          plannedFor
          suspended
        }
      }
      errors
    }
  }
`;

export const RESEND_JOB_DUE_TO_ISSUE = gql`
  mutation resendJobDueToIssue(
    $jobId: ID!
    $operatorName: String!
    $sendToCustomer: Boolean!
    $sendToInstaller: Boolean!
    $comment: JobIssueReason!
    $additionalInformation: String
  ) {
    resendJobDueToIssue(
      jobId: $jobId
      operatorName: $operatorName
      sendToCustomer: $sendToCustomer
      sendToInstaller: $sendToInstaller
      comment: $comment
      additionalInformation: $additionalInformation
    ) {
      id
      projectGrippId
      purchaseOrderGrippId
      sentOn
      isSent
      mostRecentlySentPlanning {
        sent
      }
      currentPlanning {
        sent
        plannedOn
        plannedFor
        suspended
      }
      plannings {
        created
        sent
        plannedOn
        plannedFor
        suspended
      }
    }
  }
`;

export const SEND_JOB_TO_GRIPP = gql`
  mutation sendJobToGripp($jobId: ID!) {
    sendJobToGripp(jobId: $jobId) {
      id
      projectGrippId
      purchaseOrderGrippId
    }
  }
`;

export const CHANGE_PRODUCT_ORDER_IN_INSTALLATION = gql`
  ${InstallationFragment}
  mutation changeProductOrderInInstallation($installationId: ID!, $productOrder: [ID]!) {
    changeProductOrderInInstallation(installationId: $installationId, productOrder: $productOrder) {
      id
      ...Installation
    }
  }
`;
