import { gql, useQuery } from '@apollo/client';
import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Button, Card, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Right, Small } from '@energiebespaarders/symbols/helpers';
import { ArrowUpRight, Info, Warning } from '@energiebespaarders/symbols/icons/solid';
import styled from 'styled-components';
import {
  installationLeadTimes,
  installationLeadTimesVariables,
} from '../../types/generated/installationLeadTimes';

const SimpleTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
`;

const GET_INSTALLATION_LEAD_TIMES = gql`
  query installationLeadTimes($installerId: ID!) {
    installerById(id: $installerId) {
      id
      solutions
      installationLeadTimes {
        solution
        brand {
          id
          name
          logo {
            id
            extension
          }
        }
        minDuration
        maxDuration
        note
      }
    }
  }
`;

const weeksToMonths = (weeks: number): string => {
  const months = Math.floor(weeks / 4);
  const remainingWeeks = weeks % 4;
  return `± ${months} maanden${remainingWeeks > 0 ? ` en ${remainingWeeks} weken` : ''}`;
};

interface InstallationLeadTimeProps {
  installerId: string;
}

const InstallationLeadTimes: React.FC<InstallationLeadTimeProps> = ({ installerId }) => {
  const { data, loading, error } = useQuery<installationLeadTimes, installationLeadTimesVariables>(
    GET_INSTALLATION_LEAD_TIMES,
    {
      variables: { installerId },
    },
  );

  if (loading) return <p>Laden...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const installableSolutions = data?.installerById?.solutions || [];
  const leadTimes = data?.installerById?.installationLeadTimes || [];

  const missingSolutions = installableSolutions.filter(
    s => !leadTimes.some(lt => lt.solution === s),
  );

  return (
    <div>
      <Card bgColor="blueLighter" mb={2}>
        <Small>
          <Icon solid icon={Info} fill="blue" /> Deze doorlooptijden kunnen worden gespecifeerd in
          de onderstaande Google sheet en worden aan het einde van elke werkdag gesynchroniseerd met
          ons platform.
        </Small>
        <Right block>
          <Button
            href="https://docs.google.com/spreadsheets/d/1zxnxzpnTo8LZf4yopmjh3UVKgbACcTmK68pX1XOSWao/edit?gid=0#gid=0"
            target="_blank"
            rel="noopener noreferrer"
            iconEnd={ArrowUpRight}
            fontSize={6}
            bgColor="gold"
            color="black"
            mt={1}
          >
            Doorlooptijden aanpassen
          </Button>
        </Right>
      </Card>

      {missingSolutions.length > 0 && (
        <Card bgColor="goldLighter" mt={1} mb={2}>
          <p>
            <Icon solid icon={Warning} fill="gold" /> Let op: Voor de volgende oplossingen die het
            bedrijf installeert (zie hierboven) is er nog geen doorlooptijd gespecificeerd:
          </p>
          <ul>
            {missingSolutions.map(s => (
              <li key={s}>{SOLUTIONS_NL[s]}</li>
            ))}
          </ul>
        </Card>
      )}

      {leadTimes.length === 0 ? (
        <p>Geen doorlooptijden gespecificeerd</p>
      ) : (
        <SimpleTable>
          <thead>
            <tr>
              <th>Oplossing</th>
              <th>
                <>
                  <Tooltip content="Geldt voor alle producten van deze oplossing, tenzij er een specifiek merk is aangegeven">
                    <span>Merk</span>
                  </Tooltip>
                </>
              </th>
              <th>Min. doorlooptijd (weken)</th>
              <th>Max. doorlooptijd (weken)</th>
              <th>Opmerkingen</th>
            </tr>
          </thead>
          <tbody>
            {leadTimes.map((lt, i) => (
              <tr key={`${i}-${lt.solution}-${lt.brand?.id}`}>
                <td>{SOLUTIONS_NL[lt.solution]}</td>
                <td>{lt.brand?.name || '-'}</td>
                <td>
                  <Tooltip content={weeksToMonths(lt.minDuration)}>
                    <span>{lt.minDuration}</span>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip content={weeksToMonths(lt.maxDuration)}>
                    <span>{lt.maxDuration}</span>
                  </Tooltip>
                </td>
                <td>{lt.note || '-'}</td>
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      )}
    </div>
  );
};

export default InstallationLeadTimes;
