import { gql } from '@apollo/client';
import { ConsumptionFragment } from './Consumption';

export const SituationEnergyPricesFragment = gql`
  fragment SituationEnergyPrices on EnergyPriceSituation {
    gas
    electricity
    discountedElectricity
    gasNetworkOperator
  }
`;

export const CurrentInsulationFragment = gql`
  fragment CurrentInsulation on CurrentInsulation {
    material
    locations
    thickness
  }
`;

export const WallSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment WallSituation on WallSituation {
    amountCavityBrush
    annexCoversOriginalWall
    annexInsulationRequired
    cavityWidth
    currentInsulation {
      ...CurrentInsulation
    }
    drillDiameter
    hasVentilationGrilles
    hasWildlife
    highestPointToBeInsulated
    paint
    permissionVVE
    platformRequired
    reasonNotAdvised
    rubbish
    sidesToBeInsulated
    solutionAdvised
    vegetation
    ventilationGrillesRequired
    wallsAccessible
    seamState
    moistureSpots
    wallImpregnationDesired
    impregnableArea
  }
`;

export const FloorSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment FloorSituation on FloorSituation {
    annexInsulationRequired
    currentInsulation {
      ...CurrentInsulation
    }
    finish
    musty
    permissionVVE
    reasonNotAdvised
    insulationLoosening
    ventilationGrillesRequired
    solutionAdvised
  }
`;

export const CrawlspaceSituationFragment = gql`
  fragment CrawlspaceSituation on CrawlspaceSituation {
    compartmentsAccessible
    hatchAccessible
    hatchLocation
    obstruction
    obstructionClearableByCustomer
    passagesRequired
    pipework
    rubbish
    separator
    wetness
    advisedInsulationThickness
    insulationPerimeter
    hatchWidth
    hatchLength
  }
`;

export const RoofSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment RoofSituation on RoofSituation {
    advisedAtticFloorInsulationThickness
    advisedInnerRoofInsulationProduct
    amountKneeWall
    amountPartitionWall
    amountWindowsInTargetArea
    atticIsLivingSpace
    coverRequired
    currentInsulation {
      ...CurrentInsulation
    }
    hasAsbestosInRoofing
    outsideFinishState
    permissionVVE
    possibleInsulationLocations
    purlinDistance
    reasonNotAdvised
    requiredInsulationMethod
    sandwichPanelRequired
    spareTiles
    state
    targetAreaAccess
    targetAreaWorkable
    tileLength
    tileWidth
    hasSurfaceObstacles
    isCommonRafter
    isVaporProof
    maxWorkingHeight
    amountRoomsToBeInsulated
    hasFlooring
    hasAtticFloorInsulation
    currentAtticInsulationMaterial
    purlinDepth
    canMeasureTiles
  }
`;

export const CurrentSystemFragment = gql`
  fragment CurrentSystem on CurrentSystem {
    energyYield
    energyYieldPercentage
    extensible
    inverterBrand
    panelBrand
    panelPower
    panels
    panelType
    inverterPower
    needsDisassembly
  }
`;

export const SolarSituationFragment = gql`
  ${CurrentSystemFragment}
  fragment SolarSituation on SolarSituation {
    cableRoute
    currentSystem {
      ...CurrentSystem
    }
    inverterLocation
    permissionVVE
    reasonNotAdvised
    roofShading
    accessibleThroughBack
    amountUsableSurfaces
    cableRouteCableLength
    cableRouteNotes
    hasAsbestosInRoofing
  }
`;

export const HeatingSituationFragment = gql`
  fragment HeatingSituation on HeatingSituation {
    boilerBrand
    boilerInstallationYear
    currentBoilerLocation
    CW
    flueGasOutlet
    futureGasSlurpers
    heatPumpBrand
    newBoilerLocation
    heatPumpPipelineWorkRequired
    scaffoldRequired
    reasonNotAdvised
    maintenanceContract
    furtherInsulationPlanned
    plannedInsulation
    isCurrentThermostatSameBrand
    availablePowerSocketForHeatPump
    currentHeatingTemperature
    boilerOrBufferTankAvailableWorkspace
    heatPumpInsideUnitAvailableWorkspace
    requiredItemsPlacingHeatPumpOutsideUnit
    heatPumpOutsideUnitDistanceToDrainage
    pipeRouteHeatPumpOutsideToInsideUnit
    requiredItemsPlacingAirToAirHeatPumpOutsideUnit
    cableRouteHeatPumpOutsideToInsideUnit
    pipeRouteAirToAirHeatPumpOutsideToInsideUnit
    isCookingWithGas
    waterConsumption
    expectedGasConsumptionAfterInsulation
    suggestedHeatPumpBoilerLocation
    bearingFloorMaterialAtBoiler
    heatPumpOutsideUnitDistanceToFuseBox
    hasAsbestos
    airToAirHeatPumpOutsideUnitMountType
    condensatePumpRequired
    airToAirCustomerGoal
    airToAirLocation
    airToAirAmountOutsideUnits
    airToAirPipeRouteLength
    airToAirAmountRoomsWithInsideUnits
    airToAirRoomSizes
    underfloorHeatingArea
    underfloorHeatingInstallationMethod
    underfloorHeatingMillingFloorType
    underfloorHeatingLevelingRequired
    underfloorHeatingFinishRemoval
    underfloorHeatingDistributorPowerSocket
    underfloorHeatingMillingMachineUpstairs
  }
`;

export const ElectricsSituationFragment = gql`
  fragment ElectricsSituation on ElectricsSituation {
    availableFuseGroups
    availablePowerSocket
    distributorRequired
    hasMainFuse
    isWashingMachineOnSeparateFuseGroup
    modemAccessibleFromInverter
    modemLocation
    transformerCurrent
    transformerInternetAccess
    transformerPhases
    storageCapacity
    availableFuseBoxCapacity
    requiredChangesFuseBox
    hasRCD
  }
`;

export const WindowsSituationFragment = gql`
  fragment WindowsSituation on WindowsSituation {
    amountRequiringReplacementHr
    amountRequiringReplacementSafety
    areaRequiringReplacementHr
    areaRequiringReplacementSafety
    currentWindowFinish
    floorsRequiringReplacement
    frameCostExplained
    hasOldGlazing
    largestRequiringReplacement
    leakyWindows
    reasonNotAdvised
    state
    advisedAmountVentilationGrilles
  }
`;

export const InstallerNotesFragment = gql`
  fragment InstallerNotes on Situation {
    installerNotes {
      general
      pvSystem
      wallInsulation
      floorInsulation
      crawlspaceInsulation
      innerRoofInsulation
      atticFloorInsulation
      windowGlazing
      windowFrames
      centralHeatingBoiler
      hybridHeatPump
      electricHeatPump
      airToAirHeatPump
      underfloorHeating
      chargingBox
      homeBattery
    }
  }
`;

export const ChargingBoxSituationFragment = gql`
  fragment ChargingBoxSituation on ChargingBoxSituation {
    fixedCable
    distanceFuseBox
    diggingRequired
    fuseBoxNextToOutsideWall
    attachToWall
    cableRouteDescription
    customerWillingToRepair
    metersToBeDug
    onOwnProperty
    loadBalancing
    businessExpensing
    drillingAmount
  }
`;

export const HomeBatterySituationFragment = gql`
  fragment HomeBatterySituation on HomeBatterySituation {
    advisedCapacity
    cableRoute
    cableRouteCableLength
    spaceAround
    needsFireResistantPlate
  }
`;

export const SedumSituationFragment = gql`
  fragment SedumSituation on SedumSituation {
    surface
    surfaceAngle
    area
    lossArea
    roofingAge
    eavesProfileAmount
    carryingCapacity
    hasGravel
    roofHeight
    accessibility
    preferredType
    gravelEdge
  }
`;

export const SituationFragment = gql`
  ${ConsumptionFragment}
  ${WallSituationFragment}
  ${FloorSituationFragment}
  ${CrawlspaceSituationFragment}
  ${RoofSituationFragment}
  ${SolarSituationFragment}
  ${HeatingSituationFragment}
  ${WindowsSituationFragment}
  ${ElectricsSituationFragment}
  ${InstallerNotesFragment}
  ${ChargingBoxSituationFragment}
  ${HomeBatterySituationFragment}
  ${SedumSituationFragment}
  ${SituationEnergyPricesFragment}
  fragment Situation on Situation {
    id
    consumptionAvailable
    consumption {
      ...Consumption
    }
    energyBill
    energyPrices {
      ...SituationEnergyPrices
    }
    currentOccupant
    currentSlurpers
    parkingAvailable
    walls {
      ...WallSituation
    }
    floor {
      ...FloorSituation
    }
    crawlspace {
      ...CrawlspaceSituation
    }
    roof {
      ...RoofSituation
    }
    solar {
      ...SolarSituation
    }
    heating {
      ...HeatingSituation
    }
    electrics {
      ...ElectricsSituation
    }
    windows {
      ...WindowsSituation
    }
    chargingBox {
      ...ChargingBoxSituation
    }
    homeBattery {
      ...HomeBatterySituation
    }
    sedum {
      ...SedumSituation
    }
    ...InstallerNotes
    conversionPrediction {
      probability
      solutions
      reasoning
    }
  }
`;

export default SituationFragment;
