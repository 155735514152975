import { useMutation, useQuery } from '@apollo/client';
import { SOLUTIONS_NL, Solution, SolutionDomain } from '@energiebespaarders/constants';
import { Box, Card, Flex, Icon, Placeholder, Tooltip } from '@energiebespaarders/symbols';
import { Medium, Small, TinyHeading } from '@energiebespaarders/symbols/helpers';
import { PoisonFlask, Trash, Warning } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { rgba } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import useToaster from '../../../hooks/useToaster';
import { delimit } from '../../../lib/utils';
import { stringToThemeColor } from '../../../lib/utils/colorUtils';
import { TOGGLE_ADVISED_QUOTE, updateToggleAdvisedQuoteCache } from '../../../queries/advice';
import {
  DEALS_BY_SOLUTION_DOMAIN,
  DELETE_QUOTE,
  JOBS_BY_QUOTE,
} from '../../../queries/installatron';
import { themify } from '../../../styles/mixins';
import {
  dealsByHouseSolutionDomain,
  dealsByHouseSolutionDomainVariables,
  dealsByHouseSolutionDomain_dealsByHouseSolutionDomain,
} from '../../../types/generated/dealsByHouseSolutionDomain';
import {
  deleteQuote as deleteQuoteMutation,
  deleteQuoteVariables,
} from '../../../types/generated/deleteQuote';
import { jobsByQuote, jobsByQuoteVariables } from '../../../types/generated/jobsByQuote';
import { quotesBySolution_quotesBySolution } from '../../../types/generated/quotesBySolution';
import {
  toggleAdvisedQuote,
  toggleAdvisedQuoteVariables,
} from '../../../types/generated/toggleAdvisedQuote';
import { JobType } from '../../../types/graphql-global-types';
import CompanyLogo from '../../CompanyLogo';
import DateWithTooltip from '../../DateWithTooltip';
import QuoteStatusIcons, { StatusIcon } from './QuoteStatusIcons';

type QuoteWithOnHold = quotesBySolution_quotesBySolution & { onHold: boolean };

const parseExpiration = (sentDate?: Date, expirationDate?: Date) => {
  const term = sentDate ? dayjs(expirationDate).diff(dayjs(sentDate), 'minute') : 14 * 24 * 60;
  const minutesRemaining = dayjs(expirationDate).diff(dayjs(), 'minute');
  const daysRemaining = dayjs(expirationDate).diff(dayjs(), 'day');
  const expiration = {
    color: 'purple',
    percentage: (minutesRemaining / term) * 100,
  };
  if (daysRemaining <= 7) {
    expiration.color = 'orange';
  }
  if (daysRemaining <= 3) {
    expiration.color = 'red';
  }
  return expiration;
};

function getColors(quote: QuoteWithOnHold, redundant: boolean) {
  let bgColor = 'white';
  let borderColor = 'grayLight';
  let color = redundant ? 'gray' : 'grayDarker';
  const expired = dayjs(quote.expirationDate).diff(dayjs(quote.sentOn), 'minute') <= 0;
  if (quote.onHold || quote.deal?.noLongerActive) {
    //
    bgColor = 'gray';
    borderColor = 'gray';
    color = 'gray';
  } else if (!redundant && (quote.isCancelled || expired)) {
    bgColor = 'red';
    borderColor = 'red';
    color = 'red';
  } else if (quote.isAccepted) {
    bgColor = 'green';
    borderColor = 'green';
    color = 'green';
  }
  return { bgColor, borderColor, color };
}

const DeleteIcon = styled(Icon)<any>`
  opacity: 0.25;
  transition: opacity 0.2s ${(x: any) => x.theme.curves.standard};
  cursor: pointer;
`;

interface StyledQuoteProps {
  expirationColor: string;
  quote: QuoteWithOnHold;
  bgColor: string;
  borderColor: string;
  expired?: boolean;
}

const StyledQuote = styled(Card)<StyledQuoteProps>`
  position: relative;
  background: ${x =>
    x.quote.isAccepted || x.quote.isCancelled || x.expired ? rgba(x.bgColor, 0.05) : 'white'};
  overflow: hidden;
  cursor: pointer;
  transition: background 0.15s ${x => x.theme.curves.standard};

  ${(x: { quote: QuoteWithOnHold }) =>
    x.quote.isSent &&
    !(x.quote.isCancelled || x.quote.isAccepted) &&
    css`
      border-color: ${(x: { expirationColor: string }) => x.expirationColor};
    `};

  ${(x: any) =>
    x.quote.isSent &&
    !x.quote.isAccepted &&
    !x.quote.isCancelled &&
    css<any>`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: ${(x: { expirationPercentage: string }) => x.expirationPercentage}%;
        background: ${(x: { expirationColor: string }) => rgba(x.expirationColor, 0.05)};
        border-bottom: 2px solid ${x => x.expirationColor};
        transition: width 0.2s ${x => x.theme.curves.standard};
        z-index: -1;
      }
    `};

  &:hover {
    background: ${x => rgba(x.borderColor, 0.1)};

    & ${DeleteIcon} {
      opacity: 1;
    }
  }

  &:active {
    background: ${x => rgba(x.borderColor, 0.15)};
  }
`;

const LogoWrapper = styled.div`
  max-height: 45px;
  max-width: 120px;
  width: auto;

  & img {
    width: auto;
  }
`;

type QuoteProps = {
  houseId: string;
  isAfterPtc: boolean;
  isMobile: boolean;
  openModal: () => void;
  quote: QuoteWithOnHold;
  redundant: boolean;
  solution: Solution;
  solutionDomain: SolutionDomain;
};

const Quote: React.FC<QuoteProps> = ({
  houseId,
  isAfterPtc,
  isMobile,
  openModal,
  quote,
  redundant,
  solution,
  solutionDomain,
}) => {
  const {
    isAccepted,
    isCancelled,
    items,
    reference,
    isAdvised,
    isSent,
    sentOn,
    investment,
    expirationDate,
    isSuppliable,
  } = quote;
  const toast = useToaster();
  const statusColors = getColors(quote, redundant);
  const expiration = parseExpiration(sentOn, quote.expirationDate);
  const minutesRemaining = dayjs(quote.expirationDate).diff(dayjs(), 'minute');
  const hoursRemaining = dayjs(quote.expirationDate).diff(dayjs(), 'hour');
  const daysRemaining = dayjs(expirationDate).diff(dayjs(), 'day');

  const [deleteQuote] = useMutation<deleteQuoteMutation, deleteQuoteVariables>(DELETE_QUOTE, {
    variables: { id: quote.id },
    onError: e => toast({ type: 'error', message: e.message }),
    refetchQueries: [
      {
        query: DEALS_BY_SOLUTION_DOMAIN,
        variables: { houseId, solutionDomain },
      },
    ],
    update: cache => {
      cache.evict({ id: cache.identify({ id: quote.id }) });
      const dealsQuery = cache.readQuery<
        dealsByHouseSolutionDomain,
        dealsByHouseSolutionDomainVariables
      >({
        query: DEALS_BY_SOLUTION_DOMAIN,
        variables: { houseId, solutionDomain },
      });

      const dealsInCache = dealsQuery?.dealsByHouseSolutionDomain;

      if (dealsInCache) {
        const newDealsArray: readonly dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[] = dealsInCache
          .map(deal => ({
            ...deal,
            quotes: deal.quotes.filter(q => q.id !== quote.id) || [],
          }))
          .filter(deal => deal.quotes.length);
        cache.writeQuery<dealsByHouseSolutionDomain, dealsByHouseSolutionDomainVariables>({
          query: DEALS_BY_SOLUTION_DOMAIN,
          variables: { houseId, solutionDomain },
          data: {
            dealsByHouseSolutionDomain: newDealsArray,
          },
        });
      }
      cache.gc();
    },
  });

  const [toggleAdvisedQuote] = useMutation<toggleAdvisedQuote, toggleAdvisedQuoteVariables>(
    TOGGLE_ADVISED_QUOTE,
    {
      update: (cache, res) => updateToggleAdvisedQuoteCache(cache, res, quote),
      onError: () => toast({ type: 'error', message: 'Oeps, er is iets mis gegaan' }),
    },
  );

  const { data, loading, error } = useQuery<jobsByQuote, jobsByQuoteVariables>(JOBS_BY_QUOTE, {
    variables: { quoteId: quote.id },
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error || !data) return <Placeholder error={error} />;
  const jobs = data.jobsByQuote;

  const installer = jobs.find(j => j.type === JobType.INSTALLATION || j.type === JobType.PTC)
    ?.installer;

  return (
    <StyledQuote
      animation="none"
      bgColor={themify(statusColors.bgColor)}
      borderColor={themify(statusColors.borderColor)}
      color={themify(statusColors.color)}
      expirationColor={themify(redundant ? 'grayLight' : expiration.color)}
      expired={minutesRemaining <= 0}
      onClick={openModal}
      quote={quote}
      mb={1}
      shadow="none"
    >
      <Flex flexWrap="wrap">
        <Flex width={3 / 4} flexWrap="wrap">
          <Box width={1}>
            <Medium>
              {!reference && 'CONCEPT '}OFFERTE
              {reference && ' ' + reference} - {SOLUTIONS_NL[solution].toUpperCase()}
            </Medium>
            <br />
            <Medium>€{delimit(investment)}</Medium>
            {isMobile ? <br /> : ' • '}
            {items.length > 0 &&
              (items[0].product ? items[0].product.title : 'PRODUCT NIET GEVONDEN')}
            <br />
          </Box>
          <Box width={1} py={1}>
            <QuoteStatusIcons quote={quote} jobs={jobs} files={[]} />
            {!isSuppliable && (Date.now() < expirationDate || !expirationDate) && (
              <StatusIcon iconColor={themify('red')}>
                <Tooltip
                  bgColor="red"
                  content="Deze offerte heeft artikelen die niet meer leverbaar zijn"
                  fontSize={8}
                  fontWeight={600}
                  delay={100}
                >
                  <Icon icon={Warning} solid fill="white" strokeWidth={2} width="16px" />
                </Tooltip>
              </StatusIcon>
            )}
          </Box>
          <Box width={1}>
            <Small>
              Gemaakt op <DateWithTooltip date={quote.created} format="DD-MM-YYYY" />
              {quote.sentOn ? (
                <>
                  , zichtbaar gemaakt op <DateWithTooltip date={quote.sentOn} format="DD-MM-YYYY" />
                </>
              ) : (
                ''
              )}
              {isAfterPtc && (
                <>
                  {' '}
                  <u>(na technische schouw)</u>
                </>
              )}
            </Small>
          </Box>
        </Flex>

        <Flex flexWrap="wrap" width={[1 / 4]} justifyContent="end">
          {!isMobile && !isSent && (
            <Box width={1} textAlign="right">
              <DeleteIcon
                icon={Trash}
                fill="gray"
                hoverColor="red"
                solid
                inline
                onClick={async (e: React.MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (!isAdvised) {
                    if (window.confirm('Weet je zeker dat je deze wilt verwijderen?')) {
                      deleteQuote({ variables: { id: quote.id } });
                    }
                  } else if (
                    window.confirm(
                      'De offerte staat geadviseerd. Weet je zeker dat je deze wilt verwijderen?',
                    )
                  ) {
                    // First un-advise it
                    await toggleAdvisedQuote({ variables: { quoteId: quote.id } });
                    deleteQuote({ variables: { id: quote.id } });
                  }
                }}
              />
            </Box>
          )}

          <LogoWrapper>
            {installer && (
              <CompanyLogo
                companyId={installer.id}
                size="sm"
                companyType="installer"
                maxHeight={30}
              />
            )}
          </LogoWrapper>
          {!!quote.experiments?.length && (
            <Box alignSelf="end">
              {quote.experiments?.map((experiment, i) => (
                <Tooltip key={`${experiment}-${i}`} content={`Experiment: ${experiment}`}>
                  <Icon
                    icon={PoisonFlask}
                    solid
                    color={stringToThemeColor(experiment)}
                    width="1.5em"
                    mx="0.5em"
                  />
                </Tooltip>
              ))}
            </Box>
          )}
          {!redundant && expirationDate && !isAccepted && !isCancelled && (
            <Box alignSelf="end">
              <TinyHeading color={themify(expiration.color)} textAlign="right">
                {minutesRemaining >= 0
                  ? daysRemaining <= 2
                    ? `Nog ${hoursRemaining} uur`
                    : `Nog ${daysRemaining} dagen`
                  : 'verlopen'}
              </TinyHeading>
            </Box>
          )}
        </Flex>
      </Flex>
    </StyledQuote>
  );
};

export default Quote;
